import React, {useState} from 'react'
import auth from '../../utils/auth'
import { formErrorEnum, loginStateEnum } from './data'
import FieldUsernameEmail from './FieldUsernameEmail'
import FieldPassword from './FieldPassword'
import LoginMainBtn from './LoginMainBtn'
import {Form} from 'react-bootstrap'
import 'styled-components/macro'


export default function FormEmailLogin(props) {

    // for error
    const [formState, setFormState] = useState({
        username: formErrorEnum.fieldEmpty, 
        email: "",
        password: formErrorEnum.passwordEmpty
    })



    function toForget() {
        // reset
        props.setFormInfo({
            username: "", 
            email: "",
            password: "",
            phone: "",
            mode: ""
        })
        setFormState({
            username: formErrorEnum.fieldEmpty, 
            email: "",
            password: formErrorEnum.passwordEmpty
        })

        props.setLoginState(loginStateEnum.emailForget)

    }

    function toRegister() {
        // reset
        props.setFormInfo({
            username: "", 
            email: "",
            password: "",
            phone: "",
            mode: ""
        })
        setFormState({
            username: formErrorEnum.fieldEmpty, 
            email: "",
            password: formErrorEnum.passwordEmpty
        })


        props.setLoginState(loginStateEnum.emailRegister)

    }

    return (
        <div className="emailLogin" css={`display: ${props.loginState === loginStateEnum.emailLogin ? "block" : "none"};`}>
            <Form.Group>
                <FieldUsernameEmail 
                    formState = {formState}
                    setFormState = {setFormState}
                    formInfo = {props.formInfo}
                    setFormInfo = {props.setFormInfo}
                />

                <FieldPassword
                    formState = {formState}
                    setFormState = {setFormState}
                    formInfo = {props.formInfo}
                    setFormInfo = {props.setFormInfo}
                />
            </Form.Group>

            <div className="options pb-3">
                            
                <div 
                    className="register"
                    onClick={()=>{toRegister()}}>
                        立即注册
                </div>
                <div 
                    className="forget"
                    onClick={()=>{toForget()}}>
                        忘记密码
                </div>
            </div>
                        
            <LoginMainBtn 
                formState = {formState}
                onClick = {() => {auth.loginEmail(props.formInfo)}}
                content = "登录"
            />
        </div>
    )
}
