import React, {useState} from 'react'
import './courseSection.scss'
import SectionTitle from './SectionTitle'
import SectionBody from './SectionBody'
import { withTranslation } from 'react-i18next'



function CourseSection(props) {
    const [tabKey, setTabKey] = useState(props.tabs[0])

    function setNewTabKey(newValue) {
        setTabKey(newValue)
    }
    return (

        <section id="mn-course-section" className="py-5">
            <SectionTitle 
            title = {props.title}
            des = {props.des}
            tabs = {props.tabs} 
            tabKey = {tabKey}
            setNewTabKey = {setNewTabKey}
            />
            {
                props.tabs.map((tab, idx) => (
                    <SectionBody 
                    tab = {tab} 
                    tabKey={tabKey}
                    slides={props.slides[idx]}
                    />
                ))
            }
            
        </section>
    )
}


export default withTranslation()(CourseSection)