import React from 'react'
import './streamBanner.scss'
import 'styled-components/macro'

export default function StreamBanner() {
    return (
        <section id="mn-stream-banner">
            <a className="bg" href="javascript:void(0);" target="_self"></a>
            <div className="header-placehoder">This placeholder is for the fix header</div>
            <div className="slider-wrapper">
                <div className="slider-player">
                    <video width="784" height="441" controls>
                        <source src="https://www.youtube.com/watch?v=pU_GyV5CIzA" type="video/mp4" />
                        <source src="https://www.youtube.com/watch?v=pU_GyV5CIzA" type="video/ogg" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="slider-tabs">
                    <SlideTabUnit />
                    <SlideTabUnit />
                    <SlideTabUnit />
                    <SlideTabUnit />
                    <SlideTabUnit />
                </div>
            </div>
        </section>
    )
}



function SlideTabUnit(props) {
    return (
        <div className="Slider-flashTab Slider-flashTabCover">
            <div className="Slider-flashTabImg">
                <div className="LazyLoad is-visible DyImg Slider-flashTabImgIn">
                    <img src="https://sta-op.douyucdn.cn/douyu-vrp-admin/2020/11/17/6a0bfdb0c7117f9981a8a4f04c9fcaf0.png?x-oss-process=image/format,webp/quality,q_75" className="DyImg-content is-normal " />
                </div>
                <div className="Slider-flashTabHover"></div>
            </div>
        </div>
    )
}