import React, {useState} from 'react'
import './ClickableParagraph.scss'
import {Modal} from 'react-bootstrap'
import 'styled-components/macro'


function PopupWord(props) {
    return (
        <Modal id="mn-modal-word" 
        show={props.modalIsShow} onHide={props.hideModal}
        >
            <div className="p-3">{props.word}</div>
        </Modal>
    )
}


function ClickableWord(props) {
    const [wordCardIsShow, setWordCardIsShow] = useState(false);


    return (
        <>
        <span 
            onClick={() => {setWordCardIsShow(true)}} 
            className="clickableWord py-1">
                { props.strong && 
                    <strong>
                    {props.word}
                    </strong>
                }
                { !props.strong && props.word }
                
        </span>
        <PopupWord 
        modalIsShow={wordCardIsShow}
        hideModal={() => {setWordCardIsShow(false)}}
        word={props.word}
        />
        
        </>
    )
}


export default function ClickableParagraph(props){
    /* 
    [TODO] how to exclude punctuation from each word
    1) split by " " at first  
    2) then judge if the last element is a punctuation
    3) [{content: "", word: true}]
    4) concatenate with " "
    */
    function turnToArray(p) {
        const regex = (/\b\w+/g);
        const found = p.match(regex);

        var rawArray = p.trim().split(" ");
        return rawArray
        // for (idx in rawArray) {
        //     rawArray[idx]
        // }
    }
  
    return (
        <div className="clickableParagraph" css={`display: ${props.show===false ? 'none' : 'display'}`}>
            {
                turnToArray(props.children).map((w, idx) => (
                
                <>
                <ClickableWord key={idx} word={w} strong={props.strong}/>
                &nbsp;
                </>
                ))
            }
        
        </div>
        
       
    )
}