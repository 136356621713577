import React from 'react'
import './footer.scss'
import {Container, Row, Col} from 'react-bootstrap'
import {Trans, withTranslation} from 'react-i18next'
import QRBlock from './QRBlock'
import {ImYoutube2} from 'react-icons/im'
import {FaFacebook} from 'react-icons/fa'
import {AiFillTaobaoCircle} from 'react-icons/ai'
import {qrData} from './data'


function Footer() {

    return (
        <footer id="mn-footer" className="pt-5">
            <Container> 
                <Row>
                    <Col md="3">
                        <img src={"./assets/images/mn_logo_white.png"} alt="" className="mb-3"/>
                        <p>用科技的力量，让学习变得快乐</p>
                        
                    </Col>
                    <Col md="3">
                        <h4>联系我们</h4>
                        <p>电话：+61 451 261 860</p>
                        <p>邮箱：noreply@fengcaoculture.com</p>
                        <p>地址：堪培拉， 澳大利亚</p>
                    </Col>
                    <Col md="3">
                        <h4>有用链接</h4>
                        <p><a href="https://pearsonpte.com/" target="_blank" rel="noreferrer" ><Trans>官方报名</Trans></a></p>
                        <p><a href="https://immi.homeaffairs.gov.au/help-support/tools/points-calculator" target="_blank" rel="noreferrer" ><Trans>移民算分</Trans></a></p>
                        <p><a href="https://immi.homeaffairs.gov.au/visas/visa-pricing-estimator" target="_blank" rel="noreferrer" ><Trans>签证费计算</Trans></a></p>
                        <p><a href="https://pearsonpte.com/wp-content/uploads/2019/02/PTE-Academic-Test-Taker-Handbook-Simplified-Chinese.pdf" target="_blank" rel="noreferrer" ><Trans>考生手册</Trans></a></p>
                        <p><a href="ptetrain.com" target="_blank">课程咨询</a></p>
                    </Col>
                    <Col md="3">
                        <h4>更多合作</h4>
                        <p><a href="ptetrain.com">商务合作</a></p>
                        <p><a href="ptetrain.com">成为我们的老师</a></p>
                        <p><a href="ptetrain.com">成为我们的营运人员</a></p>
                        <p><a href="ptetrain.com">加入其他团队</a></p>
                    </Col>
                </Row>
                <hr className="my-3"></hr>
                <Row>
                    <Col md="6" className="mb-3">
                        <p>关注我们</p>
                        <a href="https://tutehub.taobao.com/" target="_blank" rel="noreferrer" ><AiFillTaobaoCircle /></a>
                        <a href="https://www.youtube.com/channel/UCTSEzPwITH1HC19cK6_rDEQ" target="_blank" rel="noreferrer" ><ImYoutube2/></a>
                        <a href="https://www.facebook.com/Fengcao-Culture-109633237637604" target="_blank" rel="noreferrer" ><FaFacebook/></a>
                    </Col>
                    <Col md="6">
                        <Row>
                        {
                        qrData.map((qr, idx) =>(
                            <QRBlock 
                            key = {idx}
                            img = {qr.img}
                            des = {qr.des}
                            />
                        ))
                        }
                        </Row>
                    </Col>
                </Row>
                <div className="law">
                    <div className="copy">Copyright by <Trans>蛮牛PTE</Trans>@ {new Date().getFullYear()} </div>
                    <div className="policies">
                        <p className="mr-5"><a href="ptetrain.com"><Trans>隐私条款</Trans></a></p>
                        <p><a href="ptetrain.com"><Trans>服务协议</Trans></a></p>
                    </div>
                </div>
       
            </Container>
        </footer>
    )
}

export default withTranslation()(Footer);