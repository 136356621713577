import React, {useState} from 'react'
import FixFooter from '../general/fixFooter/FixFooter'
import FixSidebar from '../general/fixSidebar/FixSidebar'
import Footer from '../general/footer/Footer'
import Nav from '../general/nav/Nav'
import GetMaterial from '../popups/getMaterial/GetMaterial'
import About from './about/About'
import Banner from './banner/Banner'
import Compare from './compare/Compare'
import Course from './course/Course'
import System from './course/System'
import Material from './material/Material'
import Platform from './platform/Platform'
import Product from './product/Product'
import Teacher from './teacher/Teacher'
import Testimony from './testimony/Testimony'


export default function HomePage() {
    const [showPopupMaterial, setPopupMaterial] = useState(getShowPopupMaterial())


    function getShowPopupMaterial() {
        if (localStorage.getItem("showPopupMaterial")) {
            /* 
            localStorage value is a string, 
            you cannot use Boolean for 'false',
            which will always return true
            */
            return localStorage.getItem("showPopupMaterial") === 'true'
        } else {
            localStorage.setItem("showPopupMaterial", true)
            return true
        }
    }

    function hidePopupMaterial() {
        setPopupMaterial(false);
        localStorage.setItem("showPopupMaterial", false)
    }


    return (
        <div >
            <Nav />
            <main id="mn-homePage">
                <Banner />
                <Platform />
                <Material />
                <Compare />
                <Course />
                <Teacher />
                <Product />
                <Testimony />
                <About /> 
            </main>
            <Footer />
            <FixFooter />
            <FixSidebar />

            {/* <GetMaterial 
                modalIsShow = {showPopupMaterial}
                hideModal = {hidePopupMaterial}
            /> */}
        </div>

    )
}
