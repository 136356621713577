import React from 'react'
import {Form} from 'react-bootstrap'
import {formErrorEnum} from './data'


export default function FieldUsername(props) {
    function checkUsername(v) {
        // write to state
        props.setFormInfo({...props.formInfo, username: v})

        // check
        if (v === ""){
            props.setFormState({...props.ormState, username: formErrorEnum.usernameEmpty})
        } else {
            props.setFormState({...props.formState, username: ""})
        }
    }

    return (
        <div>
            <Form.Control 
                type="text" 
                placeholder="用户名" 
                value={props.formInfo.username}
                onChange={(e) => checkUsername(e.target.value)}
            />
            {
                props.formState.username !== "" &&
                <Form.Text className="text-red text-left" style={{color: "red"}}>
                    {props.formState.username}
                </Form.Text>
            }
            <br/>
        </div>
    )
}
