import React from 'react'
import {Form} from 'react-bootstrap'
import {formErrorEnum} from './data'



export default function FieldEmail(props) {
    function checkEmail(v) {
        // write to state
        props.setFormInfo({...props.formInfo, email: v})

        // check
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (v === ""){
            props.setFormState({...props.formState, email: formErrorEnum.emailEmpty})
        } else if(!re.test(String(v).toLowerCase())) {
            props.setFormState({...props.formState, email: formErrorEnum.emailInvalid})
        } else {
            
            props.setFormState({...props.formState, email: ""})
        }
    }


    return (
        <div>
            <Form.Control 
                type="email" 
                placeholder="邮箱" 
                value={props.formInfo.email} 
                onChange={(e) => checkEmail(e.target.value)}
            />
            {
                props.formState.email !== "" &&
                <Form.Text className="text-red text-left" style={{color: "red"}}>
                    {props.formState.email}
                </Form.Text>
            }
            <br />
        </div>
    )
}
