import React from 'react'
import './MockPage.scss'

export default function MemoryPage() {
    return (
        <div  id="mn-memory" className="p-2">
            <div className="practice-panel" >
                <div className="title p-2"> 最新机经 </div>
            </div>
            
        </div>
    )
}
