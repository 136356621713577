import React, {useState} from 'react'
import auth from '../../utils/auth'
import { formErrorEnum, loginStateEnum } from './data'
import FieldMatchPassword from './FieldMatchPassword'
import FieldUsername from './FieldUsername'
import FieldEmail from './FieldEmail'
import FieldPassword from './FieldPassword'
import LoginMainBtn from './LoginMainBtn'
import {Form} from 'react-bootstrap'
import 'styled-components/macro'


export default function FormEmailRegister(props) {

    // for error
    const [formState, setFormState] = useState({
        username: formErrorEnum.usernameEmpty, 
        email: formErrorEnum.emailEmpty,
        password: formErrorEnum.passwordEmpty, 
        confirmPassword: formErrorEnum.passwordEmpty,
    })


    function toLogin() {

        props.setFormInfo({
            username: "", 
            email: "",
            password: "",
            confirmPassword: "",
            phone: "",
            mode: ""
        })
        setFormState({
            username: formErrorEnum.usernameEmpty, 
            email: formErrorEnum.emailEmpty,
            password: formErrorEnum.passwordEmpty, 
            confirmPassword: formErrorEnum.passwordEmpty,
        })

        props.setLoginState(loginStateEnum.emailLogin)

    }

    function toForget() {
        props.setFormInfo({
            username: "", 
            email: "",
            password: "",
            confirmPassword: "",
            phone: "",
            mode: ""
        })
        setFormState({
            username: formErrorEnum.usernameEmpty, 
            email: formErrorEnum.emailEmpty,
            password: formErrorEnum.passwordEmpty, 
            confirmPassword: formErrorEnum.passwordEmpty,
        })

        props.setLoginState(loginStateEnum.emailForget)

    }


    return (
        <div className="emailRegister" css={`display: ${props.loginState === loginStateEnum.emailRegister ? "block" : "none"};`}>
        <Form.Group>
            <FieldUsername 
            formState = {formState}
            setFormState = {setFormState}
            formInfo = {props.formInfo}
            setFormInfo = {props.setFormInfo}
            />

            
            <FieldEmail 
            formState = {formState}
            setFormState = {setFormState}
            formInfo = {props.formInfo}
            setFormInfo = {props.setFormInfo}
            />
            

            <FieldPassword
            formState = {formState}
            setFormState = {setFormState}
            formInfo = {props.formInfo}
            setFormInfo = {props.setFormInfo}
            />


            <FieldMatchPassword 
            formState = {formState}
            setFormState = {setFormState}
            formInfo = {props.formInfo}
            setFormInfo = {props.setFormInfo}
            />

        </Form.Group>


        <div className="options pb-3">
            <div 
                className="register"
                onClick={()=>{toLogin()}}>
                账号登陆
            </div>
            <div 
                className="forget"
                onClick={()=>{toForget()}}>
                忘记密码？
            </div>
        </div>


        <LoginMainBtn 
            formState = {formState}
            onClick = {() => {auth.registerUserEmail(props.formInfo)}}
            content = "注册"
        />

        <small className="text-secondary">您阅览并同意我们的隐私条款和服务协议</small>
    </div>
    )
}
