import React, {useEffect, useState} from 'react'
import 'styled-components/macro'
import {Link, withRouter} from 'react-router-dom'
import './PracticeListPage.scss'
import axios from 'axios'
import { PATHS, BASE_URL } from '../../../../global/constants'


const speakData = ["ra", "di", "rl", "rs", "asq"]
const writeData = ["swt", "we"]
const readData = ["rfib", "rmcm", "rmcs", "ro", "rwfib"]
const listenData = ["sst", "mcm", "fib", "hcs", "hiw", "mcs", "smw", "wfd"]

function PracticeListPage(props) {
    /**
     * get question numbers for every question
     */
    const [questionCounts, setQuestionCounts] = useState({
        ra: 0, rs: 0, di: 0, rl: 0, asq: 0, 
        swt: 0, we: 0, 
        ro: 0, rmcm: 0, rmcs: 0, rfib: 0, rwfib: 0, 
        mcm: 0, mcs: 0, hiw: 0, hcs: 0, sst: 0, smw: 0, fib: 0, wfd: 0
    })

    useEffect(() => {
        Object.keys(questionCounts).map((type) => {
            axios.get(BASE_URL+"question/count/" + type)
            .then((response) => { 
                setQuestionCounts((prevState) => ({
                    ...prevState, 
                    [type]: response.data
                }))
            });
        });
        
    }, [])


    /**
     * get local question info
     */
    const [questionInfos, setQuestionInfos] = useState({
        ra: {}, rs: {}, di: {}, rl: {}, asq: {}, 
        swt: {}, we: {}, 
        ro: {}, rmcm: {}, rmcs: {}, rfib: {}, rwfib: {}, 
        mcm: {}, mcs: {}, hiw: {}, hcs: {}, sst: {}, smw: {}, fib: {}, wfd: {}
    })
    useEffect(() => {
        Object.keys(questionInfos).map((type) => {
            axios.get(process.env.PUBLIC_URL + "/assets/"+type+".json")
            .then((res) => { 
                setQuestionInfos((prevState) => ({
                    ...prevState, 
                    [type]: res.data
                }))
            });
        });
    }, [])


    return (
        <div id="mn-practice-list">
            <div className="practice-panel">
                <div className="title p-2"> 题型练习 </div>
                <div className="exercises px-5 py-3">
                    <ul className="mb-3 px-5 py-3">
                        <li className="exercises-list">
                            <p>
                                <span>Part 1</span>
                                <img src={require("../images/speak.png").default} alt="speaking" />
                                <i>口语</i>
                            </p>
                            <div className="list-tab">
                                {
                                    speakData.map((d, idx) => (
                                        <ExerciseUnit 
                                        key = {idx}
                                        destination = {`${props.match.url}${PATHS.practice[d].name}`}
                                        shortName = {questionInfos[d].shortName}
                                        name = {questionInfos[d].fullName}
                                        numOfQuestions = {questionCounts[d]}
                                        hasAI = {questionInfos[d].ai}
                                        />
                                    ))
                                }
                            </div>
                        </li>
                        <li className="exercises-list" style={{alignItems: "center"}}>
                            <p>
                                <img src={require("../images/write.png").default} alt="icon_writing" />
                                <i>写作</i>
                            </p>
                            <div className="list-tab" css={`margin-top: 20px;`}>
                                {
                                    writeData.map((d, idx) => (
                                        <ExerciseUnit 
                                        key = {idx}
                                        destination = {`${props.match.url}${PATHS.practice[d].name}`}
                                        shortName = {questionInfos[d].shortName}
                                        name = {questionInfos[d].fullName}
                                        numOfQuestions = {questionCounts[d]}
                                        hasAI = {questionInfos[d].ai}
                                        />
                                    ))
                                }
                            </div>
                        </li>
                    </ul>
                    <ul className="mb-3 px-5 py-3">
                        <li className="exercises-list">
                            <p>
                                <span>Part 2</span>
                                <img src={require("../images/read.png").default} alt="icon_reading" />
                                <i>阅读</i>
                            </p>
                            <div className="list-tab">
                                {
                                    readData.map((d, idx) => (
                                        <ExerciseUnit 
                                        key = {idx}
                                        destination = {`${props.match.url}${PATHS.practice[d].name}`}
                                        shortName = {questionInfos[d].shortName}
                                        name = {questionInfos[d].fullName}
                                        numOfQuestions = {questionCounts[d]}
                                        hasAI = {questionInfos[d].ai}
                                        />
                                    ))
                                }
                            </div>
                        </li>
                    </ul>
                    <ul className="m-0 px-5 py-3"> 
                        <li className="exercises-list">
                            <p>
                                <span>Part 3</span>
                                <img src={require("../images/listen.png").default} alt="icon_listening" className="icon_listening-3" />
                                <i>听力</i>
                            </p>
                            <div className="list-tab">
                                {
                                    listenData.map((d, idx) => (
                                        <ExerciseUnit 
                                        key = {idx}
                                        destination = {`${props.match.url}${PATHS.practice[d].name}`}
                                        shortName = {questionInfos[d].shortName}
                                        name = {questionInfos[d].fullName}
                                        numOfQuestions = {questionCounts[d]}
                                        hasAI = {questionInfos[d].ai}
                                        />
                                    ))
                                }
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default withRouter(PracticeListPage)


function ExerciseUnit(props) {
    return (
        <Link to={props.destination} className={props.hasAI ? "ai" : ""}>
            <span className="p-1">{props.shortName}</span>
            {props.name} ({props.numOfQuestions})
        </Link>
    )
}