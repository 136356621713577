import React from 'react'
import CourseItem from './CourseItem'
import {Container, Row} from 'react-bootstrap'

const data = [

    {
        title: "入门课", 
        subTitle1: "小白必备课程，PTE20个题型概览", 
        subTitle2: "适合：PTE新手或雅思转考生",
        style: {background: "linear-gradient(#d5e0fc 0%, #9cade8 100%)"}
    },
    {
        title: "真题机经班", 
        subTitle1: "讲解高频真题，特别注重口语", 
        subTitle2: "适合冲刺的同学",
        style: {background: "linear-gradient(#D7D3FA 0%, #B5B0EA 100%)"}
    },
    {
        title: "VIP", 
        subTitle1: "Flexible, specialized", 
        subTitle2: "Best for part-times",
        style: {background: "linear-gradient(#CDE8F3 0%, #96CFCB 100%)"}
    }
]

export default function FreeCourse() {
    return (
        <div id="free-courses" class="pb-3">
            <Container>
                <Row className="pb-5">
                    {
                        data.map((item, idx) => (
                            <CourseItem 
                            key = {`free-${idx}`}
                            title = {item.title}
                            subTitle1 = {item.subTitle1}
                            subTitle2 = {item.subTitle2}
                            style = {item.style}
                            />
                        ))
                    }
                </Row>
            </Container>
        </div>
    )
}
