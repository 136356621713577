import React from 'react'
import {Container} from 'react-bootstrap'


export default function SectionTitle(props) {


    return (
        <div className="course-title">
            
            <Container>
                <div className="up">
                    <div className="title">{props.title}</div>
                    {
                        props.tabs.map ((tab) => (
                            
                            <div className={`more ${props.tabKey === tab ? "active" : ""}`} onClick={() => props.setNewTabKey(tab)} >{tab}</div>
                        ))
                    }
                    <div className="more">查看更多 ></div>
                </div>
                <div className="des">{props.des}</div>
            </Container>
        </div>
    )
}
