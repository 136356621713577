import React from 'react'
import {Form, Button} from 'react-bootstrap'


export default function CourseBannerForm() {
    return (
    <Form className="right text-center p-3">
        <h4>免费领取<br />价值1080元的大礼包</h4>
        <Form.Group controlId="formBasicPassword">
            <Form.Control type="phone" placeholder="电话" />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
            <Form.Control type="email" placeholder="邮箱" />
            <Form.Text className="text-muted">
            蛮牛PTE会保障每位学员的个人隐私
            </Form.Text>
        </Form.Group>
        <Form.Group controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Check me out" />
        </Form.Group>
        <Button variant="primary" type="submit">
            点击免费领取
        </Button>
    </Form>
    )
}
