import React from 'react'
import "./AllPractices.scss"


export default function AllPractices() {
    return (
        <div id="mn-question-all-practices">
            
        </div>
    )
}
