import React from 'react'
import './courseBanner.scss'
import CourseBannerForm from './CourseBannerForm'
import {Container, Row, Col} from 'react-bootstrap'

export default function CourseBanner() {
    return (
        <section id="mn-course-banner">
            <div className="content">
                
                <Container>
                    <Row>
                        <Col md={8} className="left mb-3">
                        <div className="left">
                            <img src={require("./cow_study.png").default} alt=""/>
                            <div className='title'>
                                <h1>海外PTE直播课</h1>
                                <h5>广大学员的选择，学生遍布全球65个国家地区</h5>
                            </div>
                            <div className="btns">
                                <div>1V1解答</div>
                                <div>优质师资</div>
                                <div>高效互动</div>
                            </div>
                        </div>
                        </Col>
                        <Col md={4}>
                            <CourseBannerForm />
                        </Col>
                    </Row>
                </Container>
            </div>
             

            <div className="curve-bg">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                </svg>
            </div>
           
        </section>
    )
}
