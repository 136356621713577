import PlatformItem from './PlatformItem';
import HomeTitle from '../../general/title/HomeTitle'
import './platform.scss'
import {BiWind} from 'react-icons/bi'
import {BsAlarm} from 'react-icons/bs'
import {FaAssistiveListeningSystems, FaRegLightbulb} from 'react-icons/fa'
import 'styled-components/macro'
import BottomButton from '../../general/buttons/BottomButton'
import { Container } from 'react-bootstrap';

const itemData = [
    {
        url: '/MockExam/ImportCodePage/ImportCodePage.html?typePage=Exercise',
        img: <BiWind size="4vw" css={`background: linear-gradient(to right top, rgb(105, 118, 206), rgba(105, 118, 206, 0.5));`}/>,
        title: '口语 · Ai智能测评',
        description: '培生同芯口语纠音系统，减少真实考试试错成本',
        btnTitle: '去刷题',
        iconUrl: './assets/images/arrow_function_1.png',
        iconUrlH: './assets/images/arrow_function_h.png',
    },
    {
        url: '/MockExam/ImportCodePage/ImportCodePage.html?typePage=Exercise',
        img: <FaAssistiveListeningSystems size="4vw" css={`background: linear-gradient(to right top, rgba(51, 141, 245), rgba(51, 141, 245, 0.5));`}/>,
        title: '听力 · 精听跟读',
        description: '精编教材加AI智能评分，口语听力全方位互动，高效提分',
        btnTitle: '去精听',
        iconUrl: './assets/images/arrow_function_2.png',
        iconUrlH: './assets/images/arrow_function_h.png',
    },
    {
        url: '/MockExam/ImportCodePage/ImportCodePage.html?typePage=Exercise',
        img: <FaRegLightbulb size="4vw" css={`background: linear-gradient(to right top, rgb(44, 174, 219), rgba(44, 174, 219, 0.5));`}/>,
        title: '考前押题',
        description: '每周一发布本周的预测考题，精准押题，重要题型命中率可达99%',
        btnTitle: '练机经',
        iconUrl: './assets/images/arrow_function_3.png',
        iconUrlH: './assets/images/arrow_function_h.png',
    },
    {
        url: '/MockExam/ImportCodePage/ImportCodePage.html?typePage=Exercise',
        img: <BsAlarm size="4vw" css={`background: linear-gradient(to right top, rgba(254, 183, 94, 1), rgba(254, 183, 94, 0.5));`}/>,
        title: '真题模考',
        description: 'PTE在线全真&自由模考，教研精选考试套题，模拟真实考试场景',
        btnTitle: '做模考',
        iconUrl: './assets/images/arrow_function_4.png',
        iconUrlH: './assets/images/arrow_function_h.png',
    },
]
export default function Platform() {
    return (
      
      <section id="mn-platform" className="py-5">
        <HomeTitle  
          title = "官方练习平台"
          des = "Official真题练习材料，为所有PTEer提供全科在线学习的智能教育平台"
        />
        <Container>
            <ul className="practice-list">
                {
                itemData.map((item, idx) => (
                    <PlatformItem
                    key = {idx}
                    url={item.url}
                    img={item.img}
                    title={item.title}
                    description={item.description}
                    btnTitle={item.btnTitle}
                    iconUrl={item.iconUrl}
                    iconUrlH={item.iconUrlH}
                    />
                ))
                }
            </ul>
        </Container>

        <BottomButton btns={["备考打卡群", "PTE免费入门训练营"]}/>
      </section>

    )
}
