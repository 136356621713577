import React from 'react'
import ClickableParagraph from '../../common/clickableParagraph/ClickableParagraph'
import { QuestionWrapper } from '../QuestionWrapper'
import {Form, Button} from 'react-bootstrap'
import './PracticeRMCSPage.scss'


/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {
    return (
        <>
        <div>
        {
                props.questionData && 
                <div className="content">
                    <div className="left">
                        <ClickableParagraph>{props.questionData.content}</ClickableParagraph>
                    </div>

                    <div className="right">
                        <div className="top mb-3">
                        <ClickableParagraph strong={true}>{props.questionData.question}</ClickableParagraph>
                        {
                            props.questionData.options.map((o, idx) => {
                                return <Form.Check 
                                key = {idx}
                                type="radio"
                                id={idx}
                                label={idx+1 + ") "  + o}
                                value= {idx+1}
                                onChange={(e) => {console.log(e.target.value)}}
                            />
                            })
                        }
                        </div>
                        <Button variant="outline-primary">检查答案</Button>
                    </div>
                </div>   
            }
            {
                !props.questionData && <p>Is loading...</p> 
            }
        </div>
        </>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeRMCSPage() {
    return (
        // 
        <QuestionPage questionType="rmcs" />
    )
}