import React from 'react'
import "styled-components/macro";
import {Container, Row, Col, Tab, Nav} from 'react-bootstrap'
import HomeTitle from '../../general/title/HomeTitle';
import {Trans} from 'react-i18next';
import FreeCourse from './FreeCourse';
import VIPCourse from './VIPCourse';
import OpenCourse from './OpenCourse';
import './course.scss'
import BottomButton from '../../general/buttons/BottomButton';


export default function Course() {
    return (
        <section id="mn-course" className="py-5">
            <Container>
                <HomeTitle 
                title = "蛮牛PTE课程"
                des = "你的PTE备考学习第一课堂，从备考小白进阶到8炸大神"
                />


                <Tab.Container defaultActiveKey="first">
                <Row>
                    <Col md={2}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item><Nav.Link eventKey="first"><Trans>免费课</Trans></Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="second"><Trans>VIP课</Trans></Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="three"><Trans>公开课</Trans></Nav.Link></Nav.Item>
                    </Nav>
                    </Col>
                    <Col md={10}>
                    <Tab.Content>
                        <Tab.Pane eventKey="first"><FreeCourse /></Tab.Pane>
                        <Tab.Pane eventKey="second"><VIPCourse /></Tab.Pane>
                        <Tab.Pane eventKey="three"><OpenCourse /></Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
                </Tab.Container>
            </Container>



        <BottomButton btns={["立即报名", "现在咨询"]}/>
            
        </section>
    )
}
