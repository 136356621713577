
import { Swiper, SwiperSlide, } from 'swiper/react';
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import "styled-components/macro";
import './testimony.scss'
import HomeTitle from '../../general/title/HomeTitle'


// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);


export default function Testimony() {
    return (
        <section id="mn-testimony" className="py-5">
            <HomeTitle  
                title = "蛮牛PTE老师成绩单/战绩"
                des = "实力师资"
            />

            <Swiper
                spaceBetween={20}
                slidesPerView={4}
                navigation
                pagination={{ 
                    clickable: true
                }}
                autoplay = {{
                    delay: 3000
                }}
                speed = {1000}
                loop = {true}
                >
                {
                    [...Array(5).keys()].map((idx) => (
                    <SwiperSlide key={idx}>
                        <img src={require("./images/pte_"+(idx+1)+".png").default}  alt='' className="shadow"></img>
                    </SwiperSlide>
                    ))
                }
            </Swiper>
        </section>
    );
};