import React, {useState, useEffect, useReducer} from 'react'
import ClickableParagraph from '../../common/clickableParagraph/ClickableParagraph'
import { QuestionWrapper } from '../QuestionWrapper'
import './PracticeRMCMPage.scss'
import {Form, Button} from 'react-bootstrap'


/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {
    const [answerValues, setAnswerValues] = useState([])

    // get answer values 
    useEffect(() => {
        if (props.questionData !== undefined) {
            
        }

    }, [props.questionId])


    return (
        <div>
            {
                props.questionData && 
                <div className="content">
                    <div className="left">
                        <ClickableParagraph>{props.questionData.content}</ClickableParagraph>
                    </div>

                    <div className="right">
                        <div className="mb-3">
                        <ClickableParagraph strong={true}>{props.questionData.question}</ClickableParagraph>
                        {
                            props.questionData.options.map((o, idx) => {
                                return <Form.Check 
                                key = {idx}
                                type="checkbox"
                                id={idx}
                                label={idx+1 + ") "  + o}
                                value= {idx+1}
                                onChange={(e) => {console.log(e.target.value)}}
                            />
                            })
                        }
                        </div>
                        <Button variant="outline-primary">检查答案</Button>
                    </div>
                </div>   
            }
            {
                !props.questionData && <p>Is loading...</p> 
            }
        </div>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeRMCMPage() {
    
    const initialState = {
        selectedValues: []
    }
    
    const ACTIONS = {
        reset: "reset",
        addValue: "addValue",
        removeValue: "removeValue"
    }
    
    
    const reducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.reset:
                return {
                    selectedValues: []
                }
            case ACTIONS.addValue:
                if (!state.selectedValues.includes(action.value)) {
                    return state.selectedValues.push(action.value)
                }
            case ACTIONS.removeValue:
                if (state.selectedValues.includes(action.value)) {
                    const idx = state.selectedValues.indexOf(action.value)
                    return state.selectedValues.splice(idx, 1)
                }

            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)


    return (
        <QuestionPage 
        questionType="rmcm" 
        state = {state}
        dispatch = {dispatch}
        />
    )
}