import React from 'react'
import HomeTitle from '../../general/title/HomeTitle';
import './about.scss'

export default function About() {
    return (
        <section id="mn-about"  className="py-0">
            <HomeTitle  
            title = "关于蛮牛PTE"
            des = "蛮牛团队利用计算机专业/英语强项/考试达人/等优势，为每位考生提供贴心靠谱的资料和辅导。"
            />

            <div className="background p-5">
               <div className="rudder-box">
                  <div className="box-middle">
                     <div className="rudder rudder__1">
                        <p>教师天团</p>
                     </div>
                     <div className="rudder rudder__3">
                        <p>技术团队</p>
                     </div>
                     <div className="rudder rudder__5">
                        <p>智能分析</p>
                     </div>
                     <div className="rudder rudder__7">
                        <p>全面辅导</p>
                     </div>
                     <div className="core">
                        <img src="https://upload.fireflyau.com/web_gwpte/index/rudder-radius.png" alt=""  className="rudder-radius" />
                        <div className="slogan">
                           <p>蛮牛PTE</p>
                           <p>“核心内功”</p>
                        </div>
                        <div>
                           <div className="rudder rudder__8">
                              <p>高效刷题</p>
                           </div>
                           <div className="rudder rudder__2">
                              <p>记忆岛理论</p>
                           </div>
                           <div className="rudder rudder__4">
                              <p>速记单词</p>
                           </div>
                           <div className="rudder rudder__6">
                              <p>个性化</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
        </section>
    )
}
