import {RiVipCrownLine} from 'react-icons/ri'
import {FiHeadphones} from 'react-icons/fi'

export const data = [
    {
        title: "公开模考",
        des: "真实考试流程，AI智能成绩评定",
        color: "rgb(71, 110, 255)",
        bgColor: "rgb(198, 223, 255)",
        icon: <FiHeadphones />
    }, 
    {
        title: "VIP模考",
        des: "多套蛮牛PTE模考卷，附加自定义 模考题目模式",
        color: "rgb(230, 147, 54)",
        bgColor: "rgb(255, 238, 219)",
        icon: <RiVipCrownLine />
    }

]