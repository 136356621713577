import React from 'react'
import './streamFixSidebar.scss'
import {DiApple} from 'react-icons/di'

export default function StreamFixSidebar() {
    return (
        <div id="mn-stream-fix-sidebar">
            <div className="item" >
                <img src={require('./images/enlist.png').default} alt="enlist"/> 
                <p>开播申请</p>
            </div>
            <div className="item" >
                <div className="download-container p-3">
                    <div className="wrapper">
                        <img src={require('./images/pc.png').default} className="top" alt="download mac" /> 
                        <div className="down" >
                            <DiApple />
                            下载Mac
                        </div>
                        <div className="txt" >扫码下载APP开播</div>
                    </div>
                </div>
                <img src={require('./images/download.png').default} alt="download tools"  /> 
                <p>下载工具</p>
            </div>
            <div className="item" >
                <img src={require('./images/ctrl.png').default} alt="stream control"/> 
                <p>直播管理</p>
            </div>
        </div>
    )
}
