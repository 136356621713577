import React from 'react'
import ClickableParagraph from '../../common/clickableParagraph/ClickableParagraph'
import { QuestionWrapper } from '../QuestionWrapper'


/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {
    return (
        <>
        <div>
            {
                props.questionData && <ClickableParagraph paragraph={props.questionData.content} />  
            }
            {
                !props.questionData && <p>Is loading...</p> 
            }
        </div>
        </>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeRFIBPage() {
    return (
        // 
        <QuestionPage questionType="rfib" />
    )
}