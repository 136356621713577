import React, {useState, useEffect, useReducer} from 'react'
import WriteBox from '../../common/writeBox/WriteBox'
import { QuestionWrapper } from '../QuestionWrapper'
import AudioPlayer from '../../common/audioPlayer/AudioPlayer'


/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {

    // set play duration
    useEffect(() => {
        var audio
        if (props.questionData && props.questionData.audioPath) {
            audio = new Audio(props.questionData.audioPath)
            audio.addEventListener("loadedmetadata", () => {
                props.setPlayDuration(Math.ceil(audio.duration))
            })
        }
        return () => {
            if (audio != undefined) {
                audio.removeEventListener("loadedmetadata", () => {
                    props.setPlayDuration(audio.duration())
                })
            }
        }
    }, [props.questionData])


    return (
        <>
        <div>
            {
                props.questionData && props.questionData.audioPath &&
                <AudioPlayer 
                    state = {props.state}
                    dispatch = {props.dispatch}
                    audioSrc = {props.questionData.audioPath}
                />
            }
            {
                !props.questionData && <p>Is loading...</p> 
            }
        </div>
        <WriteBox 
        state = {props.state}
        />
        </>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeWFDPage() {
    const [playDuration, setPlayDuration] = useState(10)

    const STATES = {
        prepare: {id: -1, seconds: 3, currentTime: 3},
        play: {id: 0, seconds: playDuration, currentTime: playDuration},
        idle: {id: 1}
    }
    
    const ACTIONS = {
        reset: "reset", 
        toPlay: "toPlay",
        toIdle: "toIdle",
        increase: "increase", 
        decrease: "decrease"
    }
    
    const initialState = STATES.prepare
    
    const reducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.reset:
                return STATES.prepare
            case ACTIONS.toPlay:
                return STATES.play
            case ACTIONS.toIdle:
                return STATES.idle
            case ACTIONS.increase:
                return {...state, currentTime: state.currentTime+1}
            case ACTIONS.decrease:
                return {...state, currentTime: state.currentTime-1}
            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)


    useEffect(() => {
        let intervalRef

        // prepare
        if (state.id === -1) {
            if (state.currentTime > 0) {
                intervalRef = setInterval(() => {
                    dispatch({type: ACTIONS.decrease})
                }, 1000)
            } else {
                dispatch({type: ACTIONS.toPlay})
            }

        // play
        } else if (state.id === 0) {
            if (state.currentTime > 0) {
                intervalRef = setInterval(() => {
                    dispatch({type: ACTIONS.decrease})
                }, 1000)
            } else {
                dispatch({type: ACTIONS.toIdle})
            }

        // complete
        } else {
            clearInterval(intervalRef)
        }

        return () => {clearInterval(intervalRef)}
     
    }, [state])


    return (
        // 
        <QuestionPage 
        questionType="wfd" 
        state = {state}
        dispatch = {dispatch}
        setPlayDuration = {setPlayDuration}
        />
    )
}
