import { PATHS } from '../../../global/constants';
import {FcPortraitMode, FcOnlineSupport, FcDocument, FcReading, FcAddDatabase} from 'react-icons/fc'



export const navData = [
    {
        path: PATHS.course.name,
        title: "课程"
    }, 
    {
        path: PATHS.practice.name,
        title: "练习库"
    }, 
    {
        path: PATHS.memory.name,
        title: "机经"
    }, 
    {
        path: PATHS.mock.name,
        title: "模考"
    }, 
    {
        path: PATHS.community.name,
        title: "社区"
    }, 
    {
        path: PATHS.stream.name,
        title: "直播"
    }
]



export const navTestData = [
    {
        head: "Speaking",
        headImgName: <FcPortraitMode size="2.5em"/>,
        subs: [
            {
                name: "(RA) Read Aloud", 
                ai: <strong>AI</strong>,
                path: PATHS.practice.ra.name
            }, 
            {
                name: "(RS) Repeat Sentence",
                ai: <strong>AI</strong>,
                path: PATHS.practice.rs.name
            }, 
            {
                name: "(DI) Describe Image", 
                ai: <strong>AI</strong>,
                path: PATHS.practice.di.name
            }, 
            {
                name: "(RL) Re-tell Lecture", 
                ai: <strong>AI</strong>,
                path: PATHS.practice.rl.name
            }, 
            {
                name: "(ASQ) Answer Short Question", 
                ai: <strong>AI</strong>,
                path: PATHS.practice.asq.name
            }
        ]
    }, 
    {
        head: "Writing",
        headImgName: <FcDocument size="2.5em" />, 
        subs: [
            {
                name: "(SWT) Summarize written text", 
                ai: <strong>AI</strong>,
                path: PATHS.practice.swt.name
            }, 
            {
                name: "(WE) Write essay", 
                ai: <strong>AI</strong>,
                path: PATHS.practice.we.name
            }
        ]
    }, 
    {
        head: "Reading",
        headImgName: <FcReading size="2.5em"/>,
        subs: [
            {
                name: "(RWFIB) Fill in the Blanks",
                path: PATHS.practice.rwfib.name
            }, 
            {
                name: "(RMCM) Multiple Choice, Multiple Answer",
                path: PATHS.practice.rmcm.name
            }, 
            {
                name: "(RO) Reorder Paragraphs",
                path: PATHS.practice.ro.name
            }, 
            {
                name: "(RFIB) Fill in the Blanks",
                path: PATHS.practice.rfib.name
            }, 
            {
                name: "(RMCS) Multiple Choice, Single Answer",
                path: PATHS.practice.rmcs.name
            }
        ]
    }, 
    {
        head: "Listening",
        headImgName: <FcOnlineSupport size="2.5em" />, 
        subs: [
            {
                name: "(SST) Summarize Spoken Text", 
                ai: <strong>AI</strong>,
                path: PATHS.practice.sst.name
            }, 
            {
                name: "(MCM) Multiple Choice, Multiple Answer",
                path: PATHS.practice.mcm.name
            }, 
            {
                name: "(FIB) Fill in the Blanks",
                path: PATHS.practice.fib.name
            }, 
            {
                name: "(HCS) Highlight Correct Summary",
                path: PATHS.practice.hcs.name
            }, 
            {
                name: "(MCS) Multiple Choice, Single Answer",
                path: PATHS.practice.mcs.name
            }, 
            {
                name: "(HIW) Highlight Incorrect Words",
                path: PATHS.practice.hiw.name
            }, 
            {
                name: "(WFD) Write from Dictation", 
                ai: <strong>AI</strong>,
                path: PATHS.practice.wfd.name
            }
        ]
    }, 
    {
        head: "More Tools",
        headImgName: <FcAddDatabase size="2.5em" />, 
        subs: [
            {
                name: "Vocab books", 
            }, 
            {
                name: "Shadowing"
            }, 
            {
                name: "AI study plan"
            }, 
            {
                name: "AI score report analysis"
            }, 
            {
                name: "Templates download"
            }, 
            {
                name: "Predictions download"
            }
        ]
    }
]
