import React from 'react'
import './PredictPage.scss'



export default function PredictPage() {
    function getFirstLastDay() {
        var curr = new Date
        var firstDay = new Date(curr.setDate(curr.getDate() - curr.getDay()))
        var lastDay = new Date(curr.setDate(curr.getDate() - curr.getDay()+6))
        var firstMonth = parseInt(firstDay.getMonth()) + 1 
        var lastMonth = parseInt(lastDay.getMonth()) + 1
        var result = "【" + firstMonth + "."+firstDay.getDate() + " -- " +lastMonth+ "."+lastDay.getDate() + "】"
        return result
    }

    return (
        <div id="mn-predict">
            <div className="practice-panel p-2">
                <div className="header">
                    <span>{getFirstLastDay()} 蛮牛PTE真题预测</span>
                    <div className="header-right">
                        <div className="input-with-select el-input el-input--mini el-input-group el-input-group--append">
                            <input type="text" autocomplete="off" placeholder="SEARCH" />
                            <div className="el-input-group__append">
                                <button type="button" className="el-button el-button--default">
                                    <span>搜索</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
