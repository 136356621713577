import i18n from "i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import enJSON from './en.json';
import chJSON from './ch.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    ch: {
        translation: chJSON
    },
    en: {
        translation: enJSON
    }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ch",
    supportedLngs: ["en", "ch"],
    fallbackLng: "en",


    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;