import React from 'react'

export default function CourseCard(props) {
    return (
        <div className="course-card shadow">
            <img src="http://res.ytaxx.com/image/activity/20200901/a96a76fc8147420abecb81ffab3f6d96.png" alt="" />
            <div class="bottom p-3">
                <h5>{props.cardTitle}</h5>
                <p>{props.cardDes}</p>
            </div>
        </div>
    )
}
