import React, {useState} from 'react'
import './QuestionListDropdown.scss'
import {BiDownArrowAlt, BiUpArrowAlt} from 'react-icons/bi'

export default function QuestionListDropdown(props) {
    const [isOpen, setOpen] = useState(false);

    const toggleDropdown = () => setOpen(!isOpen);
    const handleItemClick = (item) => {
        props.setListFilter(prevState => ({
            ...prevState,
            [props.filter]: item}
        ))     
        setOpen(false)
    }

    return (
        <div id="mn-question-filter-dropdown" className="p-3">
            {/* label */}
            <span className="label">{props.filter}</span>  

            {/* current selected */}
            <div className='header' onClick={toggleDropdown}>
                {props.listFilter[props.filter]}
            {
                isOpen? <BiUpArrowAlt /> : <BiDownArrowAlt />
            }
            </div>

             {/* items */}
            <div className={`body ${isOpen && 'open'}`}>
                {
                    props.dataListFilter[props.filter].map((item, idx) => {
                        return (
                        <div 
                        key = {idx}
                        className={`p-1 item ${props.listFilter[props.filter] === item ? "selected" : ""}`}
                        onClick={() => handleItemClick(item)}>
                            {item}
                        </div>
                        )
                        
                    })
                }

            </div>
        </div>
    )
}

