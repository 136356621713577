import React, {useEffect, useState} from 'react'
import "styled-components/macro"
import './PracticeAudioIndicator.scss'
import ReactAudioPlayer from 'react-audio-player';


export default function PracticeAudioIndicator(props) {

    const [audioURL, setAudioURL] = useState("");
    const [recorder, setRecorder] = useState(null);
    const [audio, setAudio] = useState(new Audio(audioURL));
    const [playing, setPlaying] = useState(false);


    useEffect(() =>{ 
        // request at first
        if (recorder === null) {
              requestRecorder().then(setRecorder, console.error);
            return;
        }

        // recording
        if (props.state.id === 1) {
            
            if (recorder.state !== "recording") {
                recorder.start()
            }
            
        // complete
        } else if (props.state.id === 2) {
            if (recorder.state === "recording") {
                recorder.stop()
            }
        }


        // Obtain the audio when ready.
        const handleData = e => {
            const url = URL.createObjectURL(e.data)
            setAudioURL(url);
            setAudio(new Audio(url));
        };
        recorder.addEventListener("dataavailable", handleData);
        return () => recorder.removeEventListener("dataavailable", handleData);

        
    }, [props.state, recorder])


    async function requestRecorder() {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        return new MediaRecorder(stream);
    }


    useEffect(()=>{
        playing ? audio.play() : audio.pause();
    }, [audio, playing])


    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));

        return () => {
          audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, [audio]);



    function getContent() {
        switch (props.state.id) {
            // listen
            case -1: 
                return (
                    <>
                    <tr>
                        <td height="26" colSpan="2"></td>
                        <td colSpan="1"></td>
                    </tr>
                    <tr>
                        <td colSpan="3">
                            <div className="processbar processbar__prepare" css={`width: 100%;`}></div>
                        </td>
                    </tr>
                    <tr>
                        <td height="21" colSpan="3">&nbsp;</td>
                    </tr>
                    </>
                )

            case 0:
                return (
                    <>
                    <tr>
                        <td height="26" colSpan="2"><span>&nbsp;&nbsp;Beginning in {props.state.currentTime} seconds</span></td>
                        <td colSpan="1"><button className="mx-3" onClick={() => {props.dispatch({type: "toRecord"})}}>skip</button></td>
                    </tr>
                    <tr>
                        <td colSpan="3">
                            <div className="processbar processbar__prepare" css={`width: 100%;`}></div>
                        </td>
                    </tr>
                    <tr>
                        <td height="21" colSpan="3">&nbsp;</td>
                    </tr>
                    </>
                )
            case 1:
                return (
                    <>
                    <tr>
                        <td height="26" colSpan="2"><span>&nbsp;&nbsp;Recording</span></td>
                        <td colSpan="1"><button className="mx-3" onClick={() => {props.dispatch({type: "toIdle"})}}>finish</button></td>
                    </tr>
                    <tr>
                        <td colSpan="3">
                            <div className="processbar processbar__prepare" >
                                <div className="processbar processbar__practice" css={`width: ${props.state.currentTime/props.state.seconds*100}%;`}></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td height="21" colSpan="3">&nbsp;</td>
                    </tr>
                    </>
                )
            case 2:
                return (
                    <>
                    <tr>
                        <td height="26" colSpan="3"><span>&nbsp;&nbsp;Completed</span></td>
                    </tr>
                    <tr>
                        <td colSpan="3">
                            <div className="processbar processbar__prepare" >
                                <div className="processbar processbar__practice" css={`width: 100%;`}></div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td height="21" colSpan="3">&nbsp;</td>
                    </tr>
                    </>
                )
            default:
                break;
        }
    }


    if (props.state.id === 2) {
        return (
            <table className="audioIndicator">
                <tbody>
                    <tr><td colSpan="3" align="center"><b>Audio Player</b></td></tr>
                    <tr><td colSpan="3">&nbsp;</td></tr>
                    <tr>
                        <td colSpan="3">
                        <ReactAudioPlayer
                            src={audioURL}
                            autoPlay
                            controls
                        />
                        </td>
                    </tr>
                    <tr><td colSpan="3">&nbsp;</td></tr>
                    <tr>
                        <td colSpan="3" align="center"><button className="btn">Submit</button></td>
                    </tr>
                    <tr><td colSpan="3">&nbsp;</td></tr>
                </tbody>
            </table>
        )
    } else {
        return (
        <table className="audioIndicator">
            <tbody>
                <tr>
                    <td colSpan="3" align="center"><b>Recorded Answer</b></td>
                </tr>
                <tr>
                    <td colSpan="3">&nbsp;</td>
                </tr>
                <tr>
                    <td colSpan="3"><b>&nbsp;&nbsp;Current Status:</b></td>
                </tr>
                {getContent()}
            </tbody>
        </table>
        )
    }

}
