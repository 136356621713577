import React from 'react'
import { withTranslation } from 'react-i18next'
import {bottomData} from './data'
import SmallItem from './SmallItem'


function BottomItem() {
    return (
        <div className="bottom-item p-3">
            {
                bottomData.map((b) => (
                    <SmallItem 
                    className = "p-3"
                    title ={b.title}
                    des = {b.des}
                    />
                ))
            }
        </div>
    )
}

export default withTranslation()(BottomItem)