import React from 'react'
import AdminNav from './nav/AdminNav'
import {Route, Switch} from 'react-router-dom'
import AdminSideMenu from './sideMenu/AdminSideMenu'
import PracticePage from './practice/PracticePage'
import JiJingPage from './memory/MemoryPage'
import MockPage from './mock/MockPage'
import AdminHomePage from './home/AdminHomePage'
import { PATHS } from '../../global/constants'
import PredictPage from './predict/PredictPage'
import './AdminPage.scss'

export default function AdminPage(props) {
    return (
        <div id="mn-admin-page">
            <AdminNav />
            <main className="p-3 pr-5">
                <AdminSideMenu />
                <Switch>
                    <Route path={PATHS.admin.name} exact component={AdminHomePage}></Route>
                    <Route path={`${props.match.url}${PATHS.practice.name}`} component={PracticePage}></Route>
                    <Route path={`${props.match.url}${PATHS.memory.name}`} component={JiJingPage}></Route>
                    <Route path={`${props.match.url}${PATHS.predict.name}`} component={PredictPage}>
                    </Route>
                    <Route path={`${props.match.url}${PATHS.mock.name}`} component={MockPage}></Route>
                </Switch>
            </main>
        </div>
       
    )
}
