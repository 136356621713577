import React, { Component } from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import AdminPage from './components/admin/AdminPage';
import {PATHS} from './global/constants'
import HomePage from './components/home/HomePage';
import StreamPage from './components/stream/StreamPage';
import CommunityPage from './components/community/CommunityPage';
import CoursePage from './components/course/CoursePage';
import LoginPage from './components/login/LoginPage';
import ProtecedRoute from './components/route/ProtecedRoute';



export default class App extends Component {

    render() {
        return (
            <BrowserRouter>
            {/* first level */}
                <Switch>
                    <Route path={PATHS.home.name} exact component={HomePage}></Route>
                    <Route path={PATHS.course.name} component={CoursePage}></Route>
                    <Route path={PATHS.community.name} component={CommunityPage}></Route>
                    <Route path={PATHS.stream.name} component={StreamPage}></Route>
                    <ProtecedRoute path={PATHS.admin.name} component={AdminPage}></ProtecedRoute>
                    <Route path={PATHS.login.name} component={LoginPage}></Route>
                </Switch>
            </BrowserRouter>
        )
    }
}
