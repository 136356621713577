import React from 'react'
import { withTranslation } from 'react-i18next'

function CardUnit(props) {
    return (
        <div className="card-unit">
            <div className="title">{props.title}</div>
            <div className="des">{props.des}</div>
        </div>
    )
}

export default withTranslation()(CardUnit)