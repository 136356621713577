import React from 'react'
import { Trans, withTranslation} from 'react-i18next';
import './fixSidebar.scss'
import {FcConferenceCall, FcCollapse, FcFeedback, FcHeadset, FcPortraitMode, FcLikePlaceholder, FcMindMap} from 'react-icons/fc'

function FixSidebar() {
    return (
        <div id="mn-fixed-sidebar" className="text-center">
            <div className="fixed-nav-item">
                <FcLikePlaceholder />
                <p className="item_text"><Trans>领取机经</Trans></p>
            </div>
            <a className="fixed-nav-item" href="https://tutehub.taobao.com" target="_blank" rel="noreferrer">
                <FcPortraitMode />
                <p className="item_text"><Trans>咨询课程</Trans></p>
            </a>
            <div className="fixed-nav-item">
                <FcMindMap />
                <p className="item_text"><Trans>交流群</Trans></p>
                <img className="Service-Code" src="./assets/images/serviceWechatQR.png" alt="PTE,蛮牛PTE,PTE考试,蛮牛PTE名师学霸群" />
            </div>
            <div className="fixed-nav-item">
                <FcConferenceCall />
                <p className="item_text"><Trans>成为老师</Trans></p>
            </div>
            <div className="fixed-nav-item">
                <FcFeedback />
                <p className="item_text"><Trans>做问卷</Trans></p>
            </div>

            <a className="fixed-nav-item" href="" target="_blank" rel="noreferrer">
                <FcHeadset />
                <p className="item_text"><Trans>联系我们</Trans></p>
            </a>
            <FcCollapse />
            <div className="expand_handle" id="expandHandle"></div>
        </div>
    )
}


export default withTranslation()(FixSidebar)