import React from 'react'
import './navMock.scss'

function NavMock() {
    return (
        <div id="mk-list" className="mk-list">
            <div className="mk-list-item">
                <h5>模考</h5>
                <p id="StandardWork">PTE水平小测验</p>
                <p id="StandardWork">
                    <a className="isLogin" href="/Exam/levelTest.html">保过班入学测验</a>
                </p>
                <p id="MoldTestCode">免费模考卷1</p>
                <p id="MoldTestCode">免费模考卷2</p>
                <p id="MoldTestCode">免费模考卷3</p>
            </div>
            <div className="mk-list-item">
                <h5>自学辅导班</h5>
                <p id="MoldTestCode">课程码</p>
                <p id="MoldTestCode">辅导班模考</p>
            </div>
            <div className="mk-list-item">
                <h5>线下班</h5>
                <p id="MoldTestCode">课堂测验</p>
                <p id="MoldTestCode">线下班模考</p>
            </div>
        </div>
    )
}

export default NavMock
