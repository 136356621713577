import React from 'react'
import "./MyPractices.scss"


export default function MyPractices() {
    return (
        <div id="mn-question-my-practices" className="p-3">
            A paragraph 
        </div>
    )
}
