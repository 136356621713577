import React, {useState, useEffect} from 'react'
import './WriteBox.scss'


export default function WriteBox(props) {
    const [wordCount, setWordCount] = useState(0)
    const [textValue, setTextValue] = useState("")
    
    // clear the textbox if state change 
    // you cannot use state.id or if in the same state.id, it will have no effect
    useEffect(() => {
        // prepare
        if (props.state.id === -1) {
            setTextValue("")
            setWordCount(0)
        }
    }, [props.state])
    



    function changeCallback(e) {
        // word count
        var currentText = e.target.value;
        
        if(currentText.trim() !== ""){
          var wordcount = currentText.trim().split(/\s+/);
          setWordCount(wordcount.length);          
        }

        // change content
        setTextValue(e.target.value);

        // change state for wriging, listening doesn't need
        if (props.dispatch != undefined) {
            props.dispatch({type: "toIdle"})
        }
    }


    return (
        <div id="mn-write-box">
            <div className="py-2">
                <textarea rows={props.rows ? props.rows : "3"} autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" data-gramm_editor="false"
                onChange={changeCallback} value={textValue}
                >
                </textarea>
            </div>
            <div className="btns py-2">
                <button className="px-3 py-2">Cut</button>
                <button className="px-3 py-2">Copy</button>
                <button className="px-3 py-2">Paste</button>
            </div>
            <div className="py-2">Total Word Count: {wordCount}</div>
        </div>
    )
}
