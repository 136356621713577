import React from 'react'
import "styled-components/macro";
import {Container, Row} from 'react-bootstrap'
import HomeTitle from '../../general/title/HomeTitle';
import './compare.scss'
import BottomButton from '../../general/buttons/BottomButton'


export default function Compare() {
    return (
        // 蛮牛比较PTE与雅思
        <section id="mn-compare" className="py-5">
            <Container>
                <HomeTitle 
                title = "PTE与雅思对比"
                des = "留学新风向，PTE助力你的未来"
                />

                <Row>
                <div className="main">
                    <table className="price-table">
                        <tbody>
                            <tr className="table-head">
                                <td></td>
                                <td>PTE</td>
                                <td className="green-width">雅思</td>
                            </tr>
                            <tr>
                                <td>考试形式</td>
                                <td>全程机考</td>
                                <td>笔试/机考</td>
                            </tr>
                            <tr>
                                <td>适用范围</td>
                                <td>留学/移民/工作</td>
                                <td>留学/移民/工作</td>
                            </tr>
                            <tr>
                                <td>考试日期</td>
                                <td>随时报考，最近提前1天报名即可，考位充足，每天4场</td>
                                <td>固定日期，报名提前12个工作日，考位紧张，每月1-4考场</td>
                            </tr>
                            <tr>
                                <td>考试费用</td>
                                <td>275美元/优惠卷¥1700<br/>280美元（UKVI）</td>
                                <td>¥2170/2200(UKVI)</td>
                            </tr>
                            <tr>
                                <td>出成绩时间</td>
                                <td>适用院校</td>
                                <td>题库机经</td>
                            </tr>
                            <tr>
                                <td>适用院校</td>
                                <td>已被全球广泛认可</td>
                                <td>全球近10000所院校认可，美国有3000所</td>
                            </tr>
                            <tr>
                                <td>题库机经</td>
                                <td>目前机经题库较小，原题命中率高</td>
                                <td>机经题库庞大，每月5/11月为换题季，命中率极低</td>
                            </tr>
                            <tr>
                                <td>考试评分</td>
                                <td>全自动机器评分</td>
                                <td>人工评分</td>
                            </tr>
                            <tr>
                                <td>考试特点</td>
                                <td>口语：注重英文的流畅性，对发音/停顿/语音语调有明确的要求<br /></td>
                                <td><i className="fas fa-check"></i></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
              
                </Row>
            </Container>

            <BottomButton btns={["疑问咨询"]}/>
        </section>

    )
}
