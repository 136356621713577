import React from 'react'


export default function MaterialItem(props) {
    return (
        <div className="material-item shadow">
            <div className="material-item-new">NEW</div>
            <div className="material-item-title">{props.title}</div>
            <div className="material-item-des">{props.des}</div>
            <button className="material-item-btn" data-url="">{props.action}</button>
        </div>
    )
}
