import {RiComputerLine, RiRefund2Line, RiRecycleLine} from 'react-icons/ri'
import {FaChalkboardTeacher} from 'react-icons/fa'

export const data = [
    {
        icon: <RiComputerLine />,
        title: "免费试听承诺",
        des1: "首节测评课免费试听",
        des2: "满意后再付费上课"
    },
    {
        icon: <FaChalkboardTeacher />,
        title: "随时换老师承诺",
        des1: "任何时候对老师不满",
        des2: "意均可申请更换老师"
    },
    {
        icon: <RiRefund2Line />,
        title: "全额退款承诺",
        des1: "付款31天内，且所上课时不超过3节",
        des2: "可申请全额退款"
    },
    {
        icon: <RiRecycleLine />,
        title: "随时退款承诺",
        des1: "上课期间不满意",
        des2: "可随时申请退款"
    }
]