import React from 'react'
import {Col, Card, Button} from 'react-bootstrap'
import {Trans} from 'react-i18next'


export default function CourseItem(props) {

    return (
        <Col md={4} className="my-3">
            <Card className="shadow border-0 h-100" style={props.style} >
                <Card.Body>
                    <Card.Title className="m-0 mb-3"><h3><strong><Trans>{props.title}</Trans></strong></h3></Card.Title>
                    <Card.Subtitle className="mb-2"><Trans>{props.subTitle1}</Trans></Card.Subtitle>
                    <Card.Subtitle className="mb-5"><Trans>{props.subTitle2}</Trans></Card.Subtitle>
                    <Card.Link href="">
                    <Button variant="outline-light"><h5 className="m-0"><Trans>咨询</Trans></h5></Button>{' '}
                    </Card.Link>
                </Card.Body>
            </Card>
        </Col>

    )
}
