import React from 'react'
import {Modal} from 'react-bootstrap'
import {withTranslation} from 'react-i18next'
import "styled-components/macro"
import './getMaterial.scss'
import {AiOutlineCloseCircle} from 'react-icons/ai'

function GetMaterial(props) {
    return (
        <Modal id="mn-modal-popup-material" 
        className="p-3" 
        show={props.modalIsShow} onHide={props.hideModal}
        >
            <AiOutlineCloseCircle onClick={props.hideModal}/>
            <img src="/assets/images/popup_material.png" alt="popup_material" />
        </Modal>
    )
}


export default withTranslation()(GetMaterial)
