import React from 'react'
import "styled-components/macro";
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './material.scss';
import HomeTitle from '../../general/title/HomeTitle';
import MaterialItem from './MaterialItem'

const data = [
    {
        title: "PTE最新机经", 
        des: "每2周更新", 
        action: "免费下载"
    },
    {
        title: "PTE高频预测", 
        des: "每2周更新", 
        action: "免费下载"
    },
    {
        title: "写作高分模板", 
        des: "", 
        action: "打包下载"
    },
    {
        title: "听力高分模版", 
        des: "", 
        action: "打包下载"
    }
]
export default function Material() {
    return (
        // 蛮牛-备考资料
        <section id="mn-material" className="py-5">
            <Container>  
                
                <HomeTitle 
                title = "最新备考资料下载专区"
                des = "最新考试资料，高频机经任你Pick！"
                />
                <div className="material-list">
                    {
                        data.map((item, idx) => (
                            <MaterialItem 
                            key = {idx}
                            title = {item.title}
                            des = {item.des}
                            action = {item.action}
                            />
                        ))
                    }
      
                </div>
            </Container>
        </section>
    )
}

