import React from 'react'
import {FaChevronLeft} from 'react-icons/fa'
import './rightBtn.scss'


export default function RightBtn(props) {
    return (
        <button className="show-all" onClick={props.onClick}>
            <FaChevronLeft />
        </button>
    )
}
