import React from 'react'
import Nav from '../general/nav/Nav'
import LoginForm from './LoginForm'
import './loginPage.scss'


export default function LoginPage(props) {
    return (
        <div id="mn-login-page">
            <Nav />
            <LoginForm {...props}/>
        </div>
    )
}
