import React from 'react'
import {Modal} from 'react-bootstrap'
import './aiScoreTimes.scss'

export default function AIScoreTimes(props) {
    return (
        <Modal id="mn-modal-aist" centered show={props.modalIsShow} onHide={props.hideModal}>
            <div className="box text-center p-5">
                <div className="content p-4">
                    <h3>AI评分券</h3>
                    <p className="m-0">剩余 <i>8/8</i> 张</p>
                    <p className="m-0">获取AI评分需要1张评分券，每日新增2张</p>
                </div>
                <div className="buttons mt-4">
                    <span className="px-3">无限AI评分</span>
                    <span className="px-3">立即评分</span>
                </div>
            </div>
        </Modal>
    )
}
