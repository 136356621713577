export function getTypeUpper(slug) {
  var res = slug.split("-");
  return res[0].toUpperCase();
}

export function getTypeLower(slug) {
    var res = slug.split("-");
    return res[0].toLowerCase();
}

export function getQuestionID(slug) {
    var res = slug.split("-");
    return parseInt(res[1]);
}


export const questionTabEnum = {
  questionContent: "questionContent",
  myPractices: "myPractices",
  allPractices: "allPractices",
  allComments: "allComments",
  questionAnalysis: "questionAnalysis"
}




