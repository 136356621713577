import React from 'react'
import './product.scss'
import HomeTitle from '../../general/title/HomeTitle'
import app from './images/app.png'
import { Container, Row, Col} from 'react-bootstrap'
import apple from './images/apple.png'
import qr from './images/qr.png'
import {FcCheckmark} from 'react-icons/fc'

export default function Product() {
    return (
        <section id="mn-product" className="py-5">

            <HomeTitle  
                title = "蛮牛备考APP"
                des = "百万PTE考生的选择"
            />

            <Container>
                <Row>
                    <Col md={6} className="mb-5">
                        <div className="app-left">
                            <div className="app-info animation-translation-fr an-active">
                                <p><FcCheckmark /> AI评测：多题型官方同源打分系统，测评次数突破8000万次</p>
                                <p><FcCheckmark /> 高频机经：超高命中，实时打卡，权威撰写机经解析</p>
                                <p><FcCheckmark /> 精题微课：名师公开讲解备考技巧，每日强效提分</p>
                                <p><FcCheckmark /> 互动社区：分享备考经验和心得</p>
                            </div>
                            <div className="app-btns mt-5">
                                <img src={apple} alt="" className="shadow"/>
                                <img src={qr} alt="" className="shadow" />
                            </div>
                        </div>
                    
                    </Col>
                    <Col md={6} className="mb-5 animation-translation-rf an-active">
                        <div className="app-right">
                            <img src={app} alt="" className="shadow"/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
