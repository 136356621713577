
import { Swiper, SwiperSlide, } from 'swiper/react';
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade } from 'swiper';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import "styled-components/macro";

import './banner.scss'

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade]);


export default function Banner() {
  return (
      <section id="mn-home-banner">
          <Swiper
            spaceBetween={0}
            effect = {"fade"}
            slidesPerView={1}
            navigation
            pagination={{ 
                clickable: true
            }}
            onSwiper={(swiper) => console.log(swiper)}
            autoplay = {{
                delay: 5000
            }}
            speed = {1000}
            loop = {true}
            >
            {
                [...Array(3).keys()].map((idx) => (
                <SwiperSlide key={idx}>
                    <div >
                    <img src={"./assets/images/banner"+idx+".png"}  alt='' style= {{width: '100vw', height: '50vw'}}></img>
                    </div>
                </SwiperSlide>
                ))
            }
            </Swiper>
      </section>
    
  );
};