import React, { useState, useEffect } from 'react';
import TeacherHeadItem from './TeacherHeadItem'
import TeacherHeadSubItem from './TeacherHeadSubItem'
import TeacherItem from './TeacherItem'
import './teacher.scss';
import HomeTitle from '../../general/title/HomeTitle'
import {Container, Row, Col} from 'react-bootstrap'
import BottomButton from '../../general/buttons/BottomButton';

const teacherHead = [
    {
        name: "Shark",
        school: "澳洲国立大学",
        diploma: "硕士学位",
        des: "澳洲硕士毕业，GMAT满分800分考了740分（全球排名97%），PTE阅读首考84分。PTE教学经验丰富，帮助不同基础的学员短时间攻克难点，考出理想成绩。参与多项教研工作，对PTE考情把握准确。持有英语专业八级证书，BEC高级证书，NAATI证书，在澳洲曾从事口译工作。擅长成绩单分析，因材施教，上课认真负责，授课耐心细致。",
        slogan: "教学理念：Practice makes perfect"
    }, 
    {
        name: "Karita",
        school: "莫奈是大学",
        diploma: "硕士学位",
        des: "PTE全能型教师，教学功底深厚，精通题库，能准确把握考情动向。教学、英语学习教育、翻译以及国外留学生活经验丰富，逻辑清晰，要求高，擅长抓住学生弱点制定针对性提分计划。教学风格细心直接，带出高分学员众多。",
        slogan: "教学理念：分解成块 让每个学生找到最快路径"
    },
    {
        name: "Jenny", 
        school: "广东外语外贸大学", 
        diploma: "硕士学位", 
        des: "广外高翻院口译硕士；政府翻译官，英语专业八级。现为羊驼PTE特邀名师，专治备考疑难杂症，深谙口语写作听力的提分技巧，擅长用自己总结的技巧和经验帮助学生在最短的时间内突破分数瓶颈。培养出的高分学员数不胜数，深受广大学员好评。<br />上课风格直接、犀利，拒绝沉闷教学。", 
        slogan: "教学理念：业精于勤，荒于嬉"
    }, 
    {
        name: "Yulun", 
        school: "广东外语外贸大学", 
        diploma: "硕士学位", 
        des: "广外高翻院口译硕士；政府翻译官，英语专业八级。现为羊驼PTE特邀名师，专治备考疑难杂症，深谙口语写作听力的提分技巧，擅长用自己总结的技巧和经验帮助学生在最短的时间内突破分数瓶颈。培养出的高分学员数不胜数，深受广大学员好评。<br />上课风格直接、犀利，拒绝沉闷教学。", 
        slogan: "教学理念：业精于勤，荒于嬉"
    }, 
    {
        name: "Wendy", 
        school: "悉尼大学", 
        diploma: "硕士学位", 
        des: "TE九炸金牌名师，跟PTE打交道的时间非常长，见证考试发展，熟悉技巧和题库，享受教学，经验丰富，特别擅长听力、阅读教学，课堂气氛活跃，授课逻辑清晰，教学风格严谨，为人热情又负责，教学细心且耐心，成绩单分析透彻，能一针见血地指出学生问题，制定有针对性的学习方案，帮助大量被听力拖后腿的学员取得了理想的成绩。", 
        slogan: "教学理念：授人以鱼，不如授人以渔"
    }
]




const teacherItem = [
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_1", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    },
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_2", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_3", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_4", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_5", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_6", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_7", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_8", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_9", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_10", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_11", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }, 
    {
        name: "Shark", 
        title: "阅读教学主管", 
        imgName: "mn_tea_sub_12", 
        des: "首考阅读84分", 
        slogan: "刷题+方法=成功!"
    }
]



export default function Teacher() {
    const [teacherHeadIdx, setTeacherHeadIdx] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setTeacherHeadIdx(teacherHeadIdx => (teacherHeadIdx + 1)%5);
      }, 2000);
      return () => clearInterval(interval);
    }, []);


    return (

        <section id="mn-teacher" className="py-5">
            <HomeTitle  
                title = "蛮牛PTE教师矩阵"
                des = "业界权威，七重把关，千锤百炼，定期抽检，精益求精"
            />
            
            <Container className="animation-transparency an-active">
                <Row>
                    <Col md={4} className="animation-translation-fr an-active p-0 mb-3">
                        <div className="teacher-heads">
                            <div className="teacher-list">
                                {
                                    teacherHead.map( (item, idx) => (
                                    <TeacherHeadItem 
                                        key = {`tea-head-${idx}`}
                                        active = {idx === teacherHeadIdx}
                                        index = {idx+1}
                                        name = {item.name}
                                        school = {item.school}
                                        diploma = {item.diploma}
                                        des = {item.des}
                                        slogan = {item.slogan}
                                    />
                                    ))
                                }
                            </div>

                            <ul className="teacher-l-list">
                                {
                                    [...Array(5).keys()].map((idx) => (
                                        <TeacherHeadSubItem 
                                        key = {`tea-head-sub-${idx}`}
                                        active = {idx === teacherHeadIdx}
                                        index={idx+1} />
                                    ))
                                }
                            </ul>
                        </div>
                    </Col>
                        
                    <Col md={8} className="animation-translation-rf an-active p-0 mb-3">
                        <div class="teacher-subs">
                        {
                            teacherItem.map((item, idx) => (
                                <TeacherItem 
                                    key = {`tea-${idx}`}
                                    name={item.name}
                                    title={item.title}
                                    imgName={item.imgName}
                                    des={item.des}
                                />              
                            ))
                        }
                        </div>

                        
                    </Col>
                </Row>
            </Container>
            <BottomButton 
                btns = {["咨询老师", "加入我们"]}
            />
        </section>

    )
}
