import React from 'react'

export default function NavApp() {
    return (

        <div className="lxaap-menu" css={`display: none;`}>
            <ul className="lxaap-menu-nav">
                <li>
                    <h3>AI练习</h3>
                    <p>练习PTE占分高的代表性题型，智能AI自动评分，精准解析薄弱项</p>
                </li>
                <li>
                    <h3>高频机经</h3>
                    <p>超高命中率，实时更新，满分教师权威撰写机经解析，高效刷题</p>
                </li>
                <li>
                    <h3>名师带练</h3>
                    <p>名师打卡，独家带练，每日提分，突破瓶颈</p>
                </li>
                <li>
                    <h3 css={`color: #ff8817; `}>词汇精练</h3>
                    <p>百万PTEer都在用的智能背词APP</p>
                </li>
            </ul>
        </div>
    )
}
