import React, {useState} from 'react'
import './loginForm.scss'
import 'styled-components/macro'
import {Form, Row, Col, Button} from 'react-bootstrap'
import {countryCodes, loginStateEnum, formErrorEnum} from './data'
import auth from '../../utils/auth'
import FormEmailLogin from './FormEmailLogin'
import FormEmailRegister from './FormEmailRegister'
import FormEmailForget from './FormEmailForget'


export default function LoginForm(props) {
    const [countryCode, setCountryCode] = useState(countryCodes[0])
    const [loginState, setLoginState] = useState(loginStateEnum.emailLogin)

    /**
     * post to backend 
     */
    const [formInfo, setFormInfo] = useState({
        username: "", 
        email: "",
        password: "",
        phone: "",
        mode: ""
    })

    


    function headerReducer(itemType) {
        {/* Phone System */}
        // phoneRegister
        if (loginState === loginStateEnum.phoneRegister) {
            if (itemType === loginStateEnum.phoneRegister) {
                // show register 
                return "active"
            } else if (itemType === loginStateEnum.app) {
                // show app
                return ""
            } else {
                // hide others (forget, login)
                return "hide"
            }
        }
    
        else if (loginState === loginStateEnum.phoneForget) {
            if (itemType === loginStateEnum.phoneForget) {
                return "active"
            } else if (itemType === loginStateEnum.app) {
                // show app
                return ""
            } else {
                // hide others (forget, login)
                return "hide"
            }
        }
    

        /* Email System */
        if (loginState === loginStateEnum.emailRegister) {
            if (itemType === loginStateEnum.emailRegister) {
                // show register 
                return "active"
            } else if (itemType === loginStateEnum.app) {
                // show app
                return ""
            } else {
                // hide others (forget, login)
                return "hide"
            }
        }
    
        else if (loginState === loginStateEnum.emailForget) {
            if (itemType === loginStateEnum.emailForget) {
                return "active"
            } else if (itemType === loginStateEnum.app) {
                // show app
                return ""
            } else {
                // hide others (forget, login)
                return "hide"
            }
        }

        /* APP System */
        else {
            if (itemType === loginStateEnum.phoneRegister ||
                itemType === loginStateEnum.phoneForget ||
                itemType === loginStateEnum.emailRegister ||
                itemType === loginStateEnum.emailForget 
                ) {
                    return "hide"
                } else if (itemType === loginState) {
                    return "active"
                } else {
                    return ""
                }
            }
    }


    return (
        <div id="mn-login-form">
            <div className="login-box">
                <div className="header">
                    {/* Phone System */}
                    {/* <div 
                        className={`item ${headerReducer(loginStateEnum.phoneLogin)} `}
                        onClick={()=>{setLoginState(loginStateEnum.phoneLogin)}} >
                            手机登录
                    </div> 
                    <div className={`item ${headerReducer(loginStateEnum.phoneRegister)} `}>手机注册</div>
                    <div className={`item ${headerReducer(loginStateEnum.phoneForget)} `}>忘记密码</div> */}
                    
                    {/* Email System */}
                    <div 
                        className={`item ${headerReducer(loginStateEnum.emailLogin)} `}
                        onClick={()=>{setLoginState(loginStateEnum.emailLogin)}}>
                            账号登陆
                    </div>
                    <div className={`item ${headerReducer(loginStateEnum.emailRegister)} `}>立即注册</div>
                    <div className={`item ${headerReducer(loginStateEnum.emailForget)} `}>忘记密码</div>

                    {/* App System */}
                    <div 
                        className={`item ${headerReducer(loginStateEnum.app)}`}
                        onClick={()=>{setLoginState(loginStateEnum.app)}}>         
                            APP登陆
                    </div>
                </div>
                <div className="detail p-4">
                    <div className="wechat" css={`display: ${loginState === loginStateEnum.wechat ? "block" : "none"};`}>
                    </div>
                    <div className="app" css={`display: ${loginState === loginStateEnum.app ? "block" : "none"};`}>
                        App <br />
                        <small className="text-secondary">打开蛮牛APP，在首页点击右上角扫一扫图标，扫码登录</small>
                    </div>




                    {/* Email */}
                    <FormEmailRegister 
                        loginState = {loginState}
                        setLoginState = {setLoginState}
                        formInfo = {formInfo}
                        setFormInfo = {setFormInfo}
                    />

                    <FormEmailForget 
                        loginState = {loginState}
                        setLoginState = {setLoginState}
                        formInfo = {formInfo}
                        setFormInfo = {setFormInfo}
                    />

                    <FormEmailLogin 
                        loginState = {loginState}
                        setLoginState = {setLoginState}
                        formInfo = {formInfo}
                        setFormInfo = {setFormInfo}
                    />


                    {/* Phone */}
                    <div className="phoneRegister" css={`display: ${loginState === loginStateEnum.phoneRegister ? "block" : "none"};`}>
                        <Form>
                            <LoginPhone countryCode={countryCode}/>
                            <Row className="mt-3">
                                <Form.Control type="password" placeholder="请输入8-20位密码" />
                            </Row>
                            <Row className="mt-3">
                                <Form.Control type="password" placeholder="确认密码" />
                            </Row>
                            <Row className="mt-3">
                                <Col sm={8} className="p-0">
                                    <Form.Control type="text" placeholder="请输入验证码" />
                                </Col>
                                <Col sm={4} className="p-0">
                                    <Button type="submit">获取验证码</Button>
                                </Col>
                            </Row>
                        </Form>

               
                        <div className="options pt-5 pb-3">
                            <div 
                                className="register"
                                onClick={()=>{setLoginState(loginStateEnum.phoneLogin)}}>
                                手机登陆
                            </div>
                            <div 
                                className="forget"
                                onClick={()=>{setLoginState(loginStateEnum.phoneForget)}}>
                                忘记密码？
                            </div>
                        </div>
                        <div className="enter">注册</div>
                    </div>
                    <div className="phoneForget" css={`display: ${loginState === loginStateEnum.phoneForget ? "block" : "none"};`}>
                        <Form>
                            <LoginPhone countryCode={countryCode} />
                            <Row className="mt-3">
                                <Form.Control type="password" placeholder="新密码" />
                            </Row>
                            <Row className="mt-3">
                                <Form.Control type="password" placeholder="确认密码" />
                            </Row>
                            <Row className="mt-3">
                                <Col sm={8} className="p-0">
                                    <Form.Control type="text" placeholder="请输入验证码" />
                                </Col>
                                <Col sm={4} className="p-0">
                                    <Button type="submit">获取验证码</Button>
                                </Col>
                            </Row>
                        </Form>

               
                        <div className="options pt-5 pb-3">
                            <div 
                                className="register"
                                onClick={()=>{setLoginState(loginStateEnum.phoneRegister)}}>
                                立即注册
                            </div>
                            <div 
                                className="forget"
                                onClick={()=>{setLoginState(loginStateEnum.phoneLogin)}}>
                                手机登陆
                            </div>
                        </div>
                        <div className="enter">重置密码</div>
                    </div>
                    <div className="phoneLogin" css={`display: ${loginState === loginStateEnum.phoneLogin ? "block" : "none"};`}>
                        <Form>
                            <LoginPhone countryCode={countryCode} />
                            <Row className="mt-3">
                                <Form.Control type="password" placeholder="密码" />
                            </Row>
                        </Form>

               
                        <div className="options pt-5 pb-3">
                            <div 
                                className="register"
                                onClick={()=>{setLoginState(loginStateEnum.phoneRegister)}}>
                                立即注册
                            </div>
                            <div 
                                className="forget"
                                onClick={()=>{setLoginState(loginStateEnum.phoneForget)}}>
                                忘记密码？
                            </div>
                        </div>
                        <div className="enter" onClick={() => auth.login(()=>{props.history.push(props.location.state.from.pathname)})}>登录</div>
                    </div>
                </div>
            </div>
        </div>
    )
}




function LoginPhone(props) {
    return (
        <Row>
            <Col sm={4} className="p-0">
                <Form.Control as="select" defaultValue={props.countryCode}>
                    {
                        countryCodes.map((c) => (
                            <option>{c}</option>
                        ))
                    }
                </Form.Control>
            </Col>
            <Col sm={8} className="p-0">
                <Form.Control placeholder="手机号码" />
            </Col>
        </Row>
    )
}


