import React from 'react'
import {Container} from 'react-bootstrap'
import { Trans } from 'react-i18next';
import './homeTitle.scss'

export default function HomeTitle(props) {
    return (
        <Container>
            <div className="mn-title" >
                <h3 className="my-0"><Trans>{props.title}</Trans></h3>
                <span className="headline my-3"></span>
                <p css={`color: gray`}><Trans>{props.des}</Trans></p>
            </div>
        </Container>
    )
}
