import React, {useState, useEffect} from 'react'
import'./adminNav.scss'
import {FcCloseUpMode} from 'react-icons/fc'
import {RiVipFill} from 'react-icons/ri'
import {Link} from 'react-router-dom'

export default function AdminNav() {
    const [isMobile, setIsMoble] = useState(window.innerWidth <= 960)

    useEffect(() => {
        window.addEventListener("resize", updateMobileObserver);
        return () => window.removeEventListener("resize", updateMobileObserver);
    });
    const updateMobileObserver = () => (
        setIsMoble(window.innerWidth <= 960)
    )


    return (
        <div id="mn-admin-nav" className={ isMobile? "" : "px-5"}>

            <div className="header-left">
                <Link to="/"><img src="./assets/images/mn_logo.png" alt="" /></Link>
            </div>
            <div className="header-right">
                <div className="invite px-2">
                    <FcCloseUpMode size={28}/>
                    <p className="m-0">邀请有礼</p>
                </div>
                <div className="vip mx-3 px-2">
                    <RiVipFill size={28}/>
                    <p className="m-0">开通VIP</p>
                </div>
                <div className="avator">
                    <img src="https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJsuEriajrA6wWltKCB1UKa5nlb1icqBmWIKFOYeGee2z1eh2Tf9EF0LK4TTzguqTwqQ3hicavCwp86w/132" alt="portrait" />
                </div>
            </div>
        </div>
    )
}
