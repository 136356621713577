export const statisticsData = 
    {
        header: {
            name: "考试日期：2021-3-29",
            btn: "剩下0天"
        }, 
        body: [
            {
                title: "0",
                des: "今日预测"
            },
            {
                title: "433",
                des: "总共预测"
            },
            {
                title: "27",
                des: "练习天数"
            }
        ],
        footer: "点击查看"
    }


export const memoriesData = 
    {
        header: {
            name: "周更： 05/03 - 05/10",
            btn: "下载"
        }, 
        body: [
            {
                title: "69%",
                des: "重复率"
            },
            {
                title: "24",
                des: "新题"
            },
            {
                title: "1100",
                des: "预测"
            },
            {
                title: "66",
                des: "更新"
            }
        ],
        footer: "点击查看"
    }
    

export const smallData = [
    {
        className: "shadow-item p-4",
        title: "影子跟读", 
        des: ""
    },
    {
        className: "test-item p-4",
        title: "考试技巧", 
        des: ""
    },
    {
        className: "high-item p-4",
        title: "高频预测", 
        des: ""
    },
    {
        className: "tool-item p-4",
        title: "更多工具", 
        des: ""
    }
]



export const bottomData = [
    {
        title: "水平测验", 
        des: "能力测试，定制计划"
    },
    {
        title: "斩单词", 
        des: "换一种模式，自我检验"
    },
    {
        title: "导出机经", 
        des: "庞大的题库，练习不愁"
    }
]
