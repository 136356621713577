import React from 'react'
import "./AllComments.scss"

export default function AllComments() {
    return (
        <div id="mn-question-all-comments">
            
        </div>
    )
}
