import {loginStateEnum, requestModeEnum} from "../components/login/data.js"



class Auth {

    constructor() {
        this.authenticated = false;
    }
    
    

    login(cb) {
        this.authenticated = true;
        if (typeof cb === 'function') {
            cb();
        }
    }

    logout(cb) {
        this.authenticated = false;
        if (typeof cb === 'function') {
            cb();
        }
    }

    /**
     * Call backend API
     * Email mode
     */
    enableFormSubmitBtn(errorMsgs) {
        for (const [key, value] of Object.entries(errorMsgs)) {
            if (value !== "") {
                return false;
            }
        }
        return true;
    }

    registerUserEmail(payload, cb) {
        payload.mode = requestModeEnum.usernameEmailRegister
        
        console.log(payload)


        if (typeof cb === 'function') {
            cb();
        }
        
    }

    resetPasswordEmail(payload, cb) {
        if (payload.username !== "" && payload.email === "") {
            payload.mode = requestModeEnum.USERNAME_FORGET

        } else if (payload.username === "" && payload.email !== ""){
            payload.mode = requestModeEnum.EMAIL_FORGET
        }
        
        
        if (typeof cb === 'function') {
            cb();
        }
    }


    loginEmail(payload, cb) {
        if (payload.username !== "" && payload.email === "") {
            payload.mode = requestModeEnum.USERNAME_LOGIN

        } else if (payload.username === "" && payload.email !== ""){
            payload.mode = requestModeEnum.EMAIL_LOGIN
        }


        if (typeof cb === 'function') {
            cb();
        }

    }
}


export default new Auth();