import React from 'react'
import auth from '../../utils/auth'


export default function LoginMainBtn(props) {
    return (
        <div 
        className={`enter ${auth.enableFormSubmitBtn(props.formState)? "" : "disabled"}`}
        onClick={auth.enableFormSubmitBtn(props.formState)? props.onClick : undefined}
        >
            {props.content}
        </div>
    )
}
