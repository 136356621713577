import React from 'react'
import { QuestionWrapper } from '../QuestionWrapper'
import AudioPlayer from '../../common/audioPlayer/AudioPlayer'


/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {
    return (
        <>
        <div>
            {
                props.questionData && props.questionData.audioPath &&
                <AudioPlayer 
                    state = {props.state}
                    dispatch = {props.dispatch}
                    audioSrc = {props.questionData.audioPath}
                />
            }
            {
                !props.questionData && <p>Is loading...</p> 
            }
        </div>
        </>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeMCMPage() {
    return (
        // 
        <QuestionPage questionType="mcm" />
    )
}