import React from 'react'

export default function SmallItem(props) {
    return (
        <div className={`small-item ${props.className}`}>
            <h3 className="title">{props.title}</h3>
            <p className="des">{props.des}</p> 
        </div>
    )
}
