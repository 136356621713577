import React, {useState, useReducer, useEffect} from 'react'
import ClickableParagraph from '../../common/clickableParagraph/ClickableParagraph'
import { QuestionWrapper } from '../QuestionWrapper'
import {Button} from 'react-bootstrap'
import PracticeAudioIndicator from '../../common/audioIndicator/PracticeAudioIndicator'
import AudioPlayer from '../../common/audioPlayer/AudioPlayer'
import './PracticeRSPage.scss'

/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {
    const [showTranscript, setShowTranscript] = useState(false)

    // set play duration
    useEffect(() => {
        var audio
        if (props.questionData && props.questionData.audioPath) {
            audio = new Audio(props.questionData.audioPath)
            audio.addEventListener("loadedmetadata", () => {
                props.setPlayDuration(Math.ceil(audio.duration))
            })
        }
        return () => {
            if (audio != undefined) {
                audio.removeEventListener("loadedmetadata", () => {
                    props.setPlayDuration(audio.duration())
                })
            }
        }
    }, [props.questionData])
     
    
    return (
        <>
        <div>
            {
                props.questionData && props.questionData.audioPath &&

                <>
                <div className="audio-speaker mb-3">
                    <AudioPlayer 
                    state = {props.state}
                    dispatch = {props.dispatch}
                    audioSrc = {props.questionData.audioPath}
                    />
                    <PracticeAudioIndicator 
                    state={props.state}
                    dispatch = {props.dispatch}
                    />
                </div>
                <Button variant="outline-primary" onClick={() => {setShowTranscript(!showTranscript)}}>Show Transcript</Button>
                <ClickableParagraph show={showTranscript}>{props.questionData.title}</ClickableParagraph> 
                </>
            }
            {
                !props.questionData && <p>Is loading...</p> 
            }
        </div>
        </>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeRSPage() {

    const [playDuration, setPlayDuration] = useState(7)

    const STATES = {
        prepare: {id: -1, seconds: 3, currentTime: 3},
        play: {id: 0, seconds: playDuration, currentTime: playDuration},
        record: {id: 1, seconds: 15, currentTime: 0}, 
        idle: {id: 2, currentTime: -16}
    }
    
    const ACTIONS = {
        reset: "reset", 
        toPlay: "toPlay",
        toRecord: "toRecord", 
        toIdle: "toIdle",
        increase: "increase", 
        decrease: "decrease"
    }
    
    const initialState = STATES.prepare
    
    const reducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.reset:
                return STATES.prepare
            case ACTIONS.toPlay:
                return STATES.play
            case ACTIONS.toRecord:
                return STATES.record
            case ACTIONS.toIdle:
                return STATES.idle
            case ACTIONS.increase:
                return {...state, currentTime: state.currentTime+1}
            case ACTIONS.decrease:
                return {...state, currentTime: state.currentTime-1}
            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)


    useEffect(() => {
        let intervalRef

        // prepare
        if (state.id === -1) {
            if (state.currentTime > 0) {
                intervalRef = setInterval(() => {
                    dispatch({type: ACTIONS.decrease})
                }, 1000)
            } else {
                dispatch({type: ACTIONS.toPlay})
            }

        // play
        } else if (state.id === 0) {
            if (state.currentTime > 0) {
                intervalRef = setInterval(() => {
                    dispatch({type: ACTIONS.decrease})
                }, 1000)
            } else {
                dispatch({type: ACTIONS.toRecord})
            }
            
        // record
        } else if (state.id === 1) {
            if (state.currentTime < state.seconds) {
                intervalRef = setInterval(() => {
                    dispatch({type: ACTIONS.increase})
                }, 1000)
            } else {
                dispatch({type: ACTIONS.toIdle})
            }

        // complete
        } else {
            clearInterval(intervalRef)
        }

        return () => {clearInterval(intervalRef)}
     
    }, [state])




    return (
        <QuestionPage 
        questionType="rs" 
        state = {state}
        dispatch = {dispatch}
        setPlayDuration = {setPlayDuration}
        />
    )
}