import React from 'react'
import {Link} from 'react-router-dom'
import {Trans} from 'react-i18next'

export default function NavMobileUnit(props) {
    return (
        <li className={props.location.pathname.startsWith(`${props.path}`) ? "active" : ""}
        >
            <Link to={props.path}><Trans>{props.title}</Trans></Link>
        </li>
    )
}
