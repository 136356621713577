import React, {useState, useEffect} from 'react'
import './PracticeHeader.scss'
import {AiOutlineClockCircle, AiFillCaretLeft} from 'react-icons/ai'
import {getTypeUpper, questionTabEnum} from '../utils'
import {useHistory, useLocation} from 'react-router-dom'


export default function PracticeHeader(props) {
    const [seconds, setSeconds] = useState(0)

    function turnSecondsFormat(seconds) {
        return new Date(seconds * 1000).toISOString().substr(11, 8)
    }


    useEffect(() => {
        let interval = setInterval(() => {setSeconds(prevState => prevState+1)}, 1000)
        return () => clearInterval(interval)
    }, [seconds])

    /**
     * @param {*} itemType 
     * @returns 
     */
     function tabReducer(itemType){
        if (props.questionTabState === itemType) {
            return "active"
        }  else {
            return ""
        }
    }



    return (
        <div id="mn-practice-header" className="px-3 py-2">
            <div className="left">
                
                <div className="type" onClick={() => {props.setModalShow()}}>
                    <AiFillCaretLeft className="mr-2"/>
                    <span>{getTypeUpper(props.slug)}</span>
                </div>

                <div>|</div>
                <div 
                className={tabReducer(questionTabEnum.questionContent)}
                onClick={() => {props.setQuestionTabState(questionTabEnum.questionContent)}}
                >题目</div>
                <div 
                className={tabReducer(questionTabEnum.myPractices)}
                onClick={() => {props.setQuestionTabState(questionTabEnum.myPractices)}}
                >我的练习</div>
                <div 
                className={tabReducer(questionTabEnum.allPractices)}
                onClick={() => {props.setQuestionTabState(questionTabEnum.allPractices)}}
                >战友练习</div>
                <div 
                className={tabReducer(questionTabEnum.allComments)}
                onClick={() => {props.setQuestionTabState(questionTabEnum.allComments)}}
                >
                    互动评论</div>
                <div 
                className={tabReducer(questionTabEnum.questionAnalysis)}
                onClick={() => {props.setQuestionTabState(questionTabEnum.questionAnalysis)}}
                >真题讲解</div>
            </div>


            <span className="right" style={{color:"white"}}>
                <AiOutlineClockCircle />
                &nbsp;Timer:&nbsp;
                <span id="totalindextimer">{turnSecondsFormat(seconds)}</span>
            </span>
        </div>
    )
}
