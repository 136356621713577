import React from 'react'
import {withTranslation} from 'react-i18next'
import {AiOutlineRightCircle} from 'react-icons/ai'

function MockItem() {
    return (
        <div className="mock-item p-4">
            <h3>仿真模考</h3>
            <p>模拟真实考试环境</p>
            <AiOutlineRightCircle />
        </div>
    )
}

export default withTranslation()(MockItem)
