import React from 'react'
import {Container, Row} from 'react-bootstrap'
import CourseItem from './CourseItem'



const data = [

    {
        title: "全方面课程", 
        subTitle1: "20种题目，100种题型，全面涵盖", 
        subTitle2: "适合打基础的同学",
        style: {background: "linear-gradient(#d5e0fc 0%, #9cade8 100%)"}
    },
    {
        title: "真题机经班", 
        subTitle1: "讲解高频真题，特别注重口语", 
        subTitle2: "适合冲刺的同学",
        style: {background: "linear-gradient(#D7D3FA 0%, #B5B0EA 100%)"}
    },
    {
        title: "VIP", 
        subTitle1: "Flexible, specialized", 
        subTitle2: "Best for part-times",
        style: {background: "linear-gradient(#CDE8F3 0%, #96CFCB 100%)"}
    }
]


export default function OpenCourse() {
    return (
        <div id="open-courses" class="pb-3">
            <Container>
                <Row className="pb-5">
                    {
                        data.map((item, idx) => (
                            <CourseItem 
                            key = {`open-${idx}`}
                            title = {item.title}
                            subTitle1 = {item.subTitle1}
                            subTitle2 = {item.subTitle2}
                            style = {item.style}
                            />
                        ))
                    }
                </Row>
            </Container>
        </div>
    )
}
