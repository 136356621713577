import React from 'react'
import FixFooter from '../general/fixFooter/FixFooter'
import Footer from '../general/footer/Footer'
import Nav from '../general/nav/Nav'
import StreamBanner from './banner/StreamBanner'
import StreamFixSidebar from './fixSidebar/StreamFixSidebar'

export default function StreamPage() {
    return (
        <div>
            <Nav/>
            <StreamBanner />
            <main id="mn-steam-page">
            Stream Page
            </main>
            <Footer />
            <StreamFixSidebar />
            <FixFooter />
        </div>
    )
}
