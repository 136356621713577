import React from 'react'
import { QuestionWrapper } from '../QuestionWrapper'
import {Button} from 'react-bootstrap'
import "./PracticeRWFIBPage.scss"



/**
 * RWFIB Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {
    return (
        <>
        <div>
            {
                props.questionData && 
                /*[TODO] JSX自带Unicode 的转码，但是有被 XSS攻击 的危险，需要在后台做过滤*/
                <div>
                    <p dangerouslySetInnerHTML ={{__html : props.questionData.content}}></p>
                    <Button variant="outline-primary">检查答案</Button>
                </div>
                
            }
            {
                !props.questionData && <p>Is loading...</p> 
            }
        </div>
        </>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeRWFIBPage() {
    return (
        // 
        <QuestionPage questionType="rwfib" />
    )
}