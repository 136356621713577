import React from 'react'
import './bottomButton.scss'


export default function BottomButton(props) {
    return (
        <div class="btn-list mt-5 mb-3">
            {
                props.btns.map((btn) => (
                    <button class="common-advisory-btn animation-translation-bt an-active">{btn}</button>
                ))
            }
        </div>
    )
}
