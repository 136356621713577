export const PATHS = {
    home: {name: "/"},
    course: {name: "/course"},
    practice: {
        name: "/practice",
        ra: {name: "/ra"},
        rs: {name: "/rs"}, 
        rl: {name: "/rl"},
        di: {name: "/di"},
        asq: {name: "/asq"},
        swt: {name: "/swt"},
        we: {name: "/we"}, 
        rfib: {name: "/rfib"},
        rmcm: {name: "/rmcm"},
        rmcs: {name: "/rmcs"}, 
        ro: {name: "/ro"},
        rwfib: {name: "/rwfib"},
        fib: {name: "/fib"},
        hcs: {name: "/hcs"},
        hiw: {name: "/hiw"},
        mcm: {name: "/mcm"},
        mcs: {name: "/mcs"},
        smw: {name: "/smw"},
        sst: {name: "/sst"},
        wfd: {name: "/wfd"}
    },
    admin: {
        name: "/admin"
    },
    memory: {name: "/memory"},
    community: {name: "/community"},
    mock: {name: "/mock"}, 
    predict: {name: "/predict"},
    stream: {name: "/stream"},
    login: {name: "/login"}
};



// API (production)
export const BASE_URL = "https://cowpte.com:8143/api/v1/"
// API (test)
// export const BASE_URL = "http://localhost:8081/api/v1/"
