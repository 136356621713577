import React from 'react'
import { Switch, Route } from 'react-router'
import { PATHS } from '../../../global/constants';
import PracticeListPage from './list/PracticeListPage'
import PracticeASQPage from './types/asq/PracticeASQPage';
import PracticeDIPage from './types/di/PracticeDIPage';
import PracticeFIBPage from './types/fib/PracticeFIBPage';
import PracticeHCSPage from './types/hcs/PracticeHCSPage';
import PracticeHIWPage from './types/hiw/PracticeHIWPage';
import PracticeMCMPage from './types/mcm/PracticeMCMPage';
import PracticeMCSPage from './types/mcs/PracticeMCSPage';
import PracticeRAPage from './types/ra/PracticeRAPage';
import PracticeRFIBPage from './types/rfib/PracticeRFIBPage';
import PracticeRLPage from './types/rl/PracticeRLPage';
import PracticeRMCMPage from './types/rmcm/PracticeRMCMPage';
import PracticeRMCSPage from './types/rmcs/PracticeRMCSPage';
import PracticeROPage from './types/ro/PracticeROPage';
import PracticeRSPage from './types/rs/PracticeRSPage';
import PracticeRWFIBPage from './types/rwfib/PracticeRWFIBPage';
import PracticeSMWPage from './types/smw/PracticeSMWPage';
import PracticeSSTPage from './types/sst/PracticeSSTPage';
import PracticeSWTPage from './types/swt/PracticeSWTPage';
import PracticeWEPage from './types/we/PracticeWEPage';
import PracticeWFDPage from './types/wfd/PracticeWFDPage';

export default function PracticePage(props) {

    return (
        <Switch>
            <Route path={props.match.url} exact>
                <PracticeListPage />
            </Route>
            <Route path={`${props.match.url}${PATHS.practice.ra.name}`}>
                <PracticeRAPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.di.name}`}>
                <PracticeDIPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.rs.name}`}>
                <PracticeRSPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.rl.name}`}>
                <PracticeRLPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.asq.name}`}>
                <PracticeASQPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.swt.name}`}>
                <PracticeSWTPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.we.name}`}>
                <PracticeWEPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.rfib.name}`}>
                <PracticeRFIBPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.rwfib.name}`}>
                <PracticeRWFIBPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.rmcm.name}`}>
                <PracticeRMCMPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.rmcs.name}`}>
                <PracticeRMCSPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.ro.name}`}>
                <PracticeROPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.hiw.name}`}>
                <PracticeHIWPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.hcs.name}`}>
                <PracticeHCSPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.sst.name}`}>
                <PracticeSSTPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.smw.name}`}>
                <PracticeSMWPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.mcm.name}`}>
                <PracticeMCMPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.mcs.name}`}>
                <PracticeMCSPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.wfd.name}`}>
                <PracticeWFDPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.fib.name}`}>
                <PracticeFIBPage />
            </Route> 
            <Route path={`${props.match.url}${PATHS.practice.di.name}/list`} exact>
                <div>DI</div>
            </Route>
        </Switch>
    )
}
