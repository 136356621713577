import React from 'react'

export default function TeacherHeadItem(props) {
    return (
        <div className={`list-item ${props.active ? "active" : ""}`}>
            <div className="item-avatar">
                <img src={require("./images/mn_tea_head_" +props.index + ".png").default} alt="" />
                </div>
            <div className="context">
                <p className="teacher-name">{props.name}</p>
                <h5 className="label-pc">{props.school}, {props.diploma}</h5>
                <p className="text">{props.des}</p>
                <p className="lilian-text">{props.slogan}</p>
            </div>
        </div>
    )
}
