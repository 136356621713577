import React from 'react'
import {data} from './data'
import PromiseUnit from './PromiseUnit'
import './promise.scss'


export default function Promise() {
    return (
        <div id="mn-promise">
            {
                data.map((d) => (
                    <PromiseUnit 
                    icon = {d.icon}
                    title = {d.title}
                    des1 = {d.des1}
                    des2 = {d.des2}
                    />
                ))
            }
        </div>
    )
}
