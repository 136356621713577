
export default function PlatformItem(props) {
    return (
      <li className="practice-item animation-translation-bt an-active" >
        <a href={props.url}>
          <div className="item-head">
              {props.img}
            <h5>{props.title}</h5>
          </div>
          <p>{props.description}</p>
          <button>
            {props.btnTitle}
            <img className="static-icon" src={props.iconUrl} alt="" />
            <img className="hover-icon" src={props.iconUrlH} alt="" />
          </button>
        </a>
      </li>
    )
}

