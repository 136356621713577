import React, {useEffect, useState} from 'react'
import './QuestionListModal.scss'
import { getTypeUpper, getQuestionID, getTypeLower } from '../utils'
import {ListGroup, Button, Modal, Pagination, ButtonGroup} from 'react-bootstrap'
import axios from 'axios'
import { BASE_URL } from '../../../../../global/constants'
import {BsBookmark} from 'react-icons/bs'
import {BiSearchAlt, BiDownArrowAlt} from 'react-icons/bi'
import { dataListFilter, dataListSort } from './data'
import QuestionListDropdown from './QuestionListDropdown'


export default function QuestionListModal(props) {
    const [page, setPage] = useState(1)
    const [questionDatas, setQuestionDatas] = useState()


    const [listFilter, setListFilter] = useState({
        "来源": dataListFilter["来源"][0],
        "频率": dataListFilter["频率"][0],
        "练习状态": dataListFilter["练习状态"][0],
        "收藏状态": dataListFilter["收藏状态"][0],
    })
    const [listSort, setListSort] = useState(dataListSort[0])
    
    /**
     * get question data
     */
    useEffect( ()=>{
        axios.get(BASE_URL + "question/"+getTypeLower(props.slug),  
            { params: { pageNum: page, pageSize: 20, sortType: "ASC" } })
        .then((res) => {
            setQuestionDatas(res.data)
        })
        .catch(err => console.log(err))
    }, [props.slug, page])



 

    return (
        <Modal id="mn-modal-question-list" 
        className="p-3 right" 
        show={props.modalShow} 
        onHide={props.setModalShow}
        animation={false}
        size = "lg"
        onEnter = {() => {console.log("good")}}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="left">
                        <span className="title-icon p-2 mr-2">{getTypeUpper(props.slug)}</span>
                        {props.questionInfo.fullName}
                    </div>
                    <form className="searchbar mr-3">
                        <input type="text" placeholder="搜索题号" />
                        <BiSearchAlt/>
                    </form>
              
                </Modal.Title>
            </Modal.Header>

            <div className="question-filter px-3">
                {
                    Object.keys(listFilter).map((filter, idx) => {
                        return (
                            <QuestionListDropdown 
                                key = {idx}
                                filter = {filter}
                                dataListFilter = {dataListFilter}
                                listFilter = {listFilter}
                                setListFilter = {setListFilter}
                            />
                        )
                    })
                }
            </div>

            <ButtonGroup className="p-3">
                {
                    dataListSort.map((sort, idx) => {
                        return (
                        <Button 
                        key = {idx}
                        onClick={() => setListSort(sort)}
                        active={listSort === sort}
                        variant = {listSort == sort ? "primary" : "outline-secondary"}
                        size="sm"
                        >按{sort}排序</Button>
                        )
                    })
                }
            </ButtonGroup>



            <ListGroup>
                {   questionDatas && 
                    questionDatas.content.map((c, idx) => {
                        return <QuestionListUnit 
                        key ={idx}
                        data={c}
                        questionId = {props.questionId}
                        setQuestionId = {props.setQuestionId}
                        dispatch = {props.dispatch}
                        />
                    })
                }
                {
                    !questionDatas &&  
                    <div>Is loading</div>
                }
            </ListGroup>


            {/* Pagination */}
            <div className="bottom my-3 mb-5">
                {
                    questionDatas && 
                    <Pagination>
                        <Pagination.Item 
                        onClick={() => {setPage(1)}}
                        className={page == 1 ? "disabled" : ""}>{1}</Pagination.Item>
                        <Pagination.Prev 
                        onClick={() => {setPage(page-1)}}
                        className={page == 1 ? "disabled" : ""}
                        />
                        <Pagination.Item active>{questionDatas.number+1}</Pagination.Item>
                        <Pagination.Ellipsis />
                        <Pagination.Next 
                        onClick={() => {setPage(page+1)}}
                        className={page == questionDatas.totalPages ? "disabled" : ""}/>
                        <Pagination.Item 
                        onClick={() => {setPage(questionDatas.totalPages)}}
                        className={page == questionDatas.totalPages ? "disabled" : ""}>
                            {questionDatas.totalPages}
                        </Pagination.Item>
                    </Pagination>
                }
                <div>
                    跳至
                    <input 
                    type="text" 
                    placeholder=""
                    className="mx-1" 
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            setPage(e.target.value)}
                            // e.currentTarget.value = ""
                          }
                        }/>
                    页
                </div>
            </div>

            
        </Modal>
    )
}


function QuestionListUnit(props) {

    /**
     * question tags
     * @returns 
     */
    function isMemory() {
        if (!props.data.memory) {
            return (
                <div className="tag tag-blue">机经题</div>
            )
        }
    }

    function isUpdated() {
        if (!props.data.updated) {
            return (
                <div className="tag tag-green">更新</div>
            )
        }
    }

    function isAdd() {
        if (!props.data.add) {
            return (
                <div className="tag tag-orange">新题</div>
            )
        }
    }

    function getFrequency() {
        if (props.data.frequency > 0) {
            return (
                <div className="tag tag-purple">高频</div>
            )
        }
    }

    function isVerified() {
        if (!props.data.verified) {
            return (
                <div className="tag tag-burlywood">100%和原题匹配</div>
            )
        }
    }


    /**
     * change question id from list
     */
    function setId() {
        // reset question state
        if (props.dispatch !== undefined) {
            props.dispatch({type: "reset"})
        }
        props.setQuestionId(getQuestionID(props.data.questionId))
    }


    return (
        <ListGroup.Item 
        action 
        onClick={() => {setId()}} 

        className=
        {
            props.questionId == getQuestionID(props.data.questionId) ? "active" : "not-active"
        }
        >
            <div className="left">
                <span>#{getQuestionID(props.data.questionId)}</span>
                <span><strong>{props.data.title.substring(0, 25)}</strong></span>
                {isAdd()}
                {isUpdated()}
                {isMemory()}
                {getFrequency()}
                {isVerified()}
            </div>
            <div className="right">
                <div className="tag tag-red">未练习</div>
                <BsBookmark />
            </div>  
            
        </ListGroup.Item>

    )
}