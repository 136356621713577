import React, {useState, useEffect} from 'react'
import './practiceFooter.scss'
import {getQuestionID, getTypeLower} from '../utils'
import PopupTested from '../tested/PopupTested'
import PopupFeedback from '../feedback/PopupFeedback'
import axios from 'axios'
import { BASE_URL } from '../../../../../global/constants'



export default function PracticeFooter(props) {
    const [feedbackIsShow, setFeedbackIsShow] = useState(false)
    const [testedIsShow, setTestedIsShow] = useState(false)
    const [order, setOrder] = useState(true) // default is positive
    const [questionCount, setQuestionCount] = useState(0)

    const [_questionId, setQuestionId] = useState(getQuestionID(props.slug))
    useEffect(() => {
        setQuestionId(getQuestionID(props.slug))
    }, [props.slug])

    /**
     * conditional dispatch
     */
    function resetDispatch() {
        if (props.dispatch !== null && props.dispatch !== undefined) {
            props.dispatch({type: "reset"})
        }
    }


    
    /**
     * get question counts
     */
    useEffect(() => {
        
        axios.get(BASE_URL+"question/count/" + getTypeLower(props.slug))
        .then((response) => { 
            setQuestionCount(response.data)
        });

    }, [])


    return (
        <div id="mn-practice-footer" className="px-3 py-2">
            <div className="left">
                {/* because actions in each question will differ, but toPrepare is the same */}
                <span className="bg" onClick={() => {resetDispatch()}}>重做</span>
                <span className="bg" onClick={() => {setTestedIsShow(true)}}>考过</span>
                <span className="bg">收藏</span>
                <span className="bg" onClick={() => {setFeedbackIsShow(true)}}>意见反馈</span>
            </div>

            <div className="right">
                <div className="bg">
                    <span onClick={() => {setOrder(true)}}>正序</span>| 
                    <span onClick={() => {setOrder(false)}}>乱序</span>
                </div>
                <span className="bg" onClick={props.setModalShow}>选题{getQuestionID(props.slug)}/{questionCount}</span>
                <div className="bg">
                    <span 
                    className={_questionId === 1 ? "disabled": ""}
                    onClick={
                        _questionId !== 1 && (
                            () => {
                                props.setQuestionId(_questionId-1)
                                resetDispatch()
                            })
                        }>上一题</span>/
                    <span 
                    className={_questionId === questionCount ? "disabled": ""}
                    onClick={
                        _questionId !== questionCount && (
                            () => {
                                props.setQuestionId(_questionId+1)
                                resetDispatch()
                            }
                        )
                    }>下一题</span>
                </div>
            </div>

            <PopupFeedback 
            modalIsShow={feedbackIsShow}
            hideModal={()=>{setFeedbackIsShow(false)}} />
            <PopupTested 
            modalIsShow={testedIsShow}
            hideModal={()=>{setTestedIsShow(false)}}
            />
        </div>
    )
}
