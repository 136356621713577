import React, {useReducer, useEffect} from 'react'
import WriteBox from '../../common/writeBox/WriteBox'
import ClickableParagraph from '../../common/clickableParagraph/ClickableParagraph'
import {QuestionWrapper} from '../QuestionWrapper'
import './PracticeSWTPage.scss'



/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
const QuestionContent = (props) => {
    return (
        <>
        <div>
            {
                props.questionData && <ClickableParagraph>{props.questionData.content}</ClickableParagraph>
            }
            {
                !props.questionData && <p>Is loading...</p> 
            }
        </div>
        <WriteBox 
        state = {props.state}
        dispatch = {props.dispatch}
        />
        </>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeSWTPage() {
    const STATES = {
        prepare: {id: -1},
        idle: {id: 0}
    }
    
    const ACTIONS = {
        reset: "reset", 
        toIdle: "toIdle",
    }
    
    const initialState = STATES.prepare
    
    const reducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.reset:
                return STATES.prepare
            case ACTIONS.toIdle:
                return STATES.idle
            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)


    return (
        // 
        <QuestionPage 
        questionType="swt" 
        state = {state}
        dispatch = {dispatch}
        />
    )
}
