import React, {useEffect, useReducer} from 'react'
import './PracticeDIPage.scss'
import PracticeAudioIndicator from '../../common/audioIndicator/PracticeAudioIndicator'
import ImageUploader from '../../common/uploadImage/ImageUploader'




import { QuestionWrapper } from '../QuestionWrapper'


/**
 * SWT Content
 * @param {*} props 
 * @returns 
 */
 const QuestionContent = (props) => {
    return (
        <div className="content">
            {
                props.questionData && (props.questionData.imagePath !== "") && 
                <img src={props.questionData.imagePath} alt={"di-"+props.questionId} />
            }
            {
                !props.questionData || (props.questionData.imagePath === "") && <ImageUploader 
                questionType="di" 
                questionId={props.questionId}
                questionData = {props.questionData} />
            }
                    
            <PracticeAudioIndicator 
                state={props.state}
                dispatch={props.dispatch}
            />
        </div>
    )
}

const QuestionPage = QuestionWrapper(QuestionContent);

export default function PracticeDIPage() {
    const STATES = {
        prepare: {id: 0, seconds: 40, currentTime: 40},
        record: {id: 1, seconds: 40, currentTime: 0},
        idle: {id: 2, currentTime: -41}
    }
    
    const ACTIONS = {
        reset: "reset",
        toRecord: "toRecord",
        toIdle: "toIdle",
        decrease: "decrease", 
        increase: "increase"
    }
    
    const initialState = STATES.prepare
    
    const reducer = (state, action) => {
        switch (action.type) {
            case ACTIONS.reset:
                return STATES.prepare
            case ACTIONS.toRecord:
                return STATES.record
            case ACTIONS.toIdle:
                return STATES.idle
            case ACTIONS.decrease:
                return {...state, currentTime: state.currentTime-1}
            case ACTIONS.increase:
                return {...state, currentTime: state.currentTime+1}
            default:
                throw new Error();
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        let intervalRef;
        if (state.id === 0) {
            if (state.currentTime > 0) {
                intervalRef = setInterval(() => {
                    dispatch({type: ACTIONS.decrease})
                }, 1000)
            } else {
                dispatch({type: ACTIONS.skip})
            }
        } else if (state.id === 1) {
            if (state.currentTime < state.seconds) {
                intervalRef = setInterval(() => {
                    dispatch({type: ACTIONS.increase})
                }, 1000)
            } else {
                dispatch({type: ACTIONS.stop})
            }
        } else {
            clearInterval(intervalRef)
        }
        return () => {clearInterval(intervalRef)}
    }, [state])




    return (
        // 
        <QuestionPage 
        questionType="di" 
        state = {state}
        dispatch = {dispatch}
        />
    )
}