import React from 'react'

export default function PromiseUnit(props) {
    return (
        <div className="item p-3">
            {props.icon}
            <div className="wrap">
                <h5>{props.title}</h5>
                <p>{props.des1}</p>
                <p>{props.des2}</p>
            </div>
        </div>
    )
}
