import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import 'styled-components/macro'


export default function PopupFeedback(props) {
    return (
        <Modal id="mn-modal-feedback" show={props.modalIsShow} onHide={props.hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>问题反馈</Modal.Title>
            </Modal.Header>

            <Modal.Body>
            <div className="el-dialog__body">
      <div  role="radiogroup" className="el-radio-group">
         <label  role="radio" tabindex="-1" className="el-radio radioSelect">
            <span className="el-radio__input"><span className="el-radio__inner"></span><input type="radio" aria-hidden="true" tabindex="-1" className="el-radio__original" value="1" /></span>
            <span className="el-radio__label">
               未及时更新
            </span>
         </label>
         <label  role="radio" tabindex="-1" className="el-radio radioSelect">
            <span className="el-radio__input"><span className="el-radio__inner"></span><input type="radio" aria-hidden="true" tabindex="-1" className="el-radio__original" value="2" /></span>
            <span className="el-radio__label">
               文本有错误
            </span>
         </label>
         <label  role="radio" tabindex="-1" className="el-radio radioSelect">
            <span className="el-radio__input"><span className="el-radio__inner"></span><input type="radio" aria-hidden="true" tabindex="-1" className="el-radio__original" value="3" /></span>
            <span className="el-radio__label">
               音频有错误
            </span>
         </label>
         <label  role="radio" tabindex="-1" className="el-radio radioSelect">
            <span className="el-radio__input"><span className="el-radio__inner"></span><input type="radio" aria-hidden="true" tabindex="-1" className="el-radio__original" value="4" /></span>
            <span className="el-radio__label">
               讨论区不良言论
            </span>
         </label>
         <label  role="radio" aria-checked="true" tabindex="0" className="el-radio radioSelect is-checked" >
            <span className="el-radio__input is-checked"><span className="el-radio__inner"></span><input type="radio" aria-hidden="true" tabindex="-1" className="el-radio__original" value="5" /></span>
            <span className="el-radio__label">
               其他
            </span>
         </label>
      </div>
      <div  className="el-textarea" >
         <textarea autocomplete="off" placeholder="请输入内容" className="el-textarea__inner"></textarea>
         
      </div>
   </div>

            </Modal.Body>

            <Modal.Footer>
            <Button variant="secondary" onClick={props.hideModal}>
                取消
            </Button>
            <Button variant="primary" onClick={props.hideModal}>
                确认
            </Button>
            </Modal.Footer>
        </Modal>

    )
}
