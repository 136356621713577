import React, {useState, useReducer, useEffect, useRef} from 'react'
import PracticeFooter from '../common/footer/PracticeFooter'
import PracticeHeader from '../common/header/PracticeHeader'
import PracticePrompt from '../common/prompt/PracticePrompt'
import 'styled-components/macro'
import axios from 'axios';
import RightBtn from '../common/rightBtn/RightBtn'
import QuestionListModal from '../common/questionListModal/QuestionListModal'
import { BASE_URL } from '../../../../global/constants'
import { questionTabEnum } from '../common/utils'
import MyPractices from '../common/myPratices/MyPractices'
import AllComments from '../common/allComments/AllComments'
import QuestionAnalysis from '../common/questionAnalysis/QuestionAnalysis'
import AllPractices from '../common/allPractices/AllPractices'




/**
 * Higher order component
 * @param {*} Component 
 * @returns 
 */
export const QuestionWrapper = (Component) => 


    function Comp(props) {

        const [questionId, setQuestionId] = useState(1)
        const [questionData, setQuestionData] = useState()
        const [listShow, setListShow] = useState(false)
        const [questionTabState, setQuestionTabState] = useState(questionTabEnum.questionContent)
        const [questionInfo, setQuestionInfo] = useState({})


        /**
         *  get question data
         */
        useEffect( ()=>{
            axios.get(BASE_URL + "question/"+props.questionType+"/"+questionId)
            .then((res) => {
                setQuestionData(res.data)
            })
            .catch(err => console.log(err))

        }, [questionId])


        /**
         * get local file
         * because it is HOC, so it becomes ../assets/
         */
        useEffect(() =>{
            axios
            .get(process.env.PUBLIC_URL + "/assets/" + props.questionType + ".json")
            .then((res) => {
                setQuestionInfo(res.data)
            })
            .catch(err => console.log(err))
        }, [props.questionType])



    return (
        <div id={`mn-practice-${props.questionType}`} className="mn-practice">
            <div className="practice-panel">
                <PracticeHeader 
                slug={`${props.questionType}-${questionId}`} 
                questionTabState = {questionTabState}
                setQuestionTabState = {setQuestionTabState}
                setModalShow = {() => {setListShow(true)}}
                />
                
                {
                    questionTabState === questionTabEnum.questionContent &&
                    <div className="body p-3">
                        <PracticePrompt 
                        prompt = {questionInfo.prompt}
                        />

                       <Component
                       {...props}
                       questionId = {questionId}
                       questionData = {questionData}
                       />
                    </div>
                }
                {
                    questionTabState === questionTabEnum.myPractices &&
                    <MyPractices slug={`${props.questionType}-${questionId}`}  />
                }
                {
                    questionTabState === questionTabEnum.allPractices &&
                    <AllPractices slug={`${props.questionType}-${questionId}`}  />
                }
                {
                    questionTabState === questionTabEnum.allComments &&
                    <AllComments slug={`${props.questionType}-${questionId}`}   />
                }
                {
                    questionTabState === questionTabEnum.questionAnalysis &&
                    <QuestionAnalysis slug={`${props.questionType}-${questionId}`}  />
                }

                <PracticeFooter 
                    slug={`${props.questionType}-${questionId}`} 
                    setQuestionId={setQuestionId}
                    setModalShow = {() => {setListShow(true)}}
                    dispatch = {props.dispatch}
                />

            </div>

            <RightBtn 
            onClick = {() => setListShow(!listShow)}
            />
            <QuestionListModal 
            slug = {`${props.questionType}-${questionId}`} 
            modalShow = {listShow}
            setModalShow = {() => {setListShow(false)}}
            questionInfo = {questionInfo}
            questionId = {questionId}
            setQuestionId = {setQuestionId}
            dispatch = {props.dispatch}
            />
        </div>
    )
    }

