import React from 'react'
import Nav from '../general/nav/Nav'
import Footer from '../general/footer/Footer'
import FixFooter from '../general/fixFooter/FixFooter'
import FixSidebar from '../general/fixSidebar/FixSidebar'
import CourseBanner from './banner/CourseBanner'
import CourseSection from './section/CourseSection'
import './coursePage.scss'
import {data} from './data'
import Promise from '../general/promise/Promise'


export default function CoursePage() {
    return (
        <div id="mn-course-page">
            <Nav />
            <main id="mn-course-body">
                <CourseBanner />
                {
                    data.map((d) => (
                        <CourseSection 
                        title = {d.title}
                        des = {d.des}
                        tabs = {d.tabs}
                        slides = {d.slides}
                        />
                    ))
                }
            </main>
            <Promise />
            <Footer />
            <FixFooter />
            <FixSidebar />
        </div>
        
    )
}
