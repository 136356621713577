import React from 'react'
import "./QuestionAnalysis.scss"


export default function QuestionAnalysis() {
    return (
        <div id="mn-question-analysis">
            
        </div>
    )
}
