import {FcHome, FcDocument, FcCrystalOscillator, FcSimCard, FcSelfie, FcSportsMode, FcSupport, FcStatistics, FcTodoList, FcVoicePresentation, FcWikipedia, FcOnlineSupport, FcScatterPlot, FcTimeline, FcStackOfPhotos, FcGraduationCap, FcFaq, FcElectricity} from 'react-icons/fc'
import { PATHS } from '../../../global/constants'



export const adminMenuData = [
    {
        path: PATHS.admin.name,
        icon: <FcHome />,
        title: "首页"
    },
    {
        path: PATHS.admin.name+PATHS.practice.name,
        icon: <FcDocument />,
        title: "练习库"
    },
    {
        path: PATHS.admin.name+PATHS.memory.name, 
        icon: <FcSimCard />,
        title: "机经"
    },
    {
        path: PATHS.admin.name+PATHS.predict.name, 
        icon: <FcSportsMode />,
        title: "预测"
    },
    {
        path: PATHS.admin.name+PATHS.mock.name, 
        icon: <FcSelfie />,
        title: "模考"
    },
    {
        path: "#", 
        icon: <FcStackOfPhotos />,
        title: "PTE题型概览"
    },
    {
        path: "#", 
        icon: <FcGraduationCap />,
        title: "三分钟课堂"
    },
    {
        path: "#", 
        icon: <FcOnlineSupport />,
        title: "名师辅导"
    },
    {
        path: "#", 
        icon: <FcWikipedia />,
        title: "单词本"
    },
    {
        path: "#", 
        icon: <FcOnlineSupport />,
        title: "我的收藏"
    },
    {
        path: "#", 
        icon: <FcTodoList />,
        title: "每日计划"
    },
    {
        path: "#", 
        icon: <FcStatistics />,
        title: "PTE教研报告"
    },
    {
        path: "#", 
        icon: <FcTimeline />,
        title: "成绩分析"
    },
    {
        path: "#", 
        icon: <FcScatterPlot />,
        title: "练习分析"
    },
    {
        path: "#", 
        icon: <FcElectricity />,
        title: "PTE训练营"
    },
    {
        path: "#", 
        icon: <FcVoicePresentation />,
        title: "经验分享"
    },
    {
        path: "#", 
        icon: <FcFaq />,
        title: "常见问题"
    },
    {
        path: "#", 
        icon: <FcSupport />,
        title: "设置"
    },
    {
        path: "#", 
        icon: <FcCrystalOscillator />,
        title: "退出登陆"
    }
]