import React, {useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'
import {BiRightArrowAlt} from 'react-icons/bi'
import './MockPage.scss'
import {Link} from 'react-router-dom'
import {data} from './data.js'
import 'styled-components/macro'


export default function MockPage() {
    return (
        <div id="mn-mock">
            <div className="practice-panel">
                <div className="header">
                    <img src={require('./mock-banner.png').default} alt="mock-banner" />
                </div>
                <div className="body p-5">
                    <Row>
                        {
                            data.map((d, idx) => {
                                return (
                                <Col md={6} className="p-2" key={idx}>
                                    <div className="p-3" css={`color: ${d.bgColor};`}>
                                        {d.icon}
                                        <h4>{d.title}</h4>
                                        <p>{d.des}</p>
                                        <Link 
                                            to="" 
                                            css={`color: ${d.color}; background-color: ${d.bgColor}`}
                                        >
                                            <div>去模考 <BiRightArrowAlt /></div>
                                        </Link>
                                    </div>  
                                </Col>
                                )
                            })
                        }
                    </Row>
                </div>

                <div className="bottom p-5">
                    <div className='left'>
                        <h4>历史模考成绩单</h4>
                        <p>直接查看以往成绩分析单，获取AI智能建议...</p>
                    </div>

                    <div className="right">
                        <Link to="">
                            <div> 去查看 <BiRightArrowAlt /></div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
