import React, {useEffect} from 'react'
import {Trans} from 'react-i18next'
import {Link} from 'react-router-dom'
import { PATHS } from '../../../global/constants';
import { adminMenuData } from './data';

function AdminSideMenuUnit(props) {

    function isActive(urlPath, tabPath) {
        // complete match
        if (urlPath === tabPath) {
            return true
        } 
        
        // complete not match
        if (!urlPath.match(tabPath)) {
            return false;

        } else {
            // partially match
            if (tabPath === PATHS.admin.name) {
                return false;
            } else {
                return true;
            }
        }

    }

    return (
        
        <li className={isActive(props.location.pathname, props.path)? 'active' : undefined}>
            <Link to={props.path} activeClassName="active">
                {props.icon}
                <p><Trans>{props.title}</Trans></p>
            </Link>
        </li>
    )
}


export default AdminSideMenuUnit;


