import React, {useState} from 'react';
import './teacherItem.scss';

export default function TeacherItem(props) {
  const [flipped, setFlipped] = useState(false)

  return (
    <div className="sub-item">
      <div 
        // onMouseEnter={() => setFlipped(!flipped)} 
        // onMouseLeave={() => setFlipped(!flipped)} 
        className={"card-container" + (flipped ? " flipped" : "")}>
        
        <div className="front">
          <div className="image-container">
            <img src={require("./images/"+props.imgName+".png").default} alt=""></img>
          </div>
        </div>

        
        <div className="back">
          <p>{props.des}</p>
        </div>
      </div>
    </div>
  )
}




