import React from 'react'
import {Col} from 'react-bootstrap'
import './qrBlock.scss'

export default function QRBlock(props) {
    return (

        <Col>
            <img src={"./assets/images/" + props.img + ".png"} alt="" className="mb-3" />
            <p>{props.des}</p>
        </Col>
    )
}
