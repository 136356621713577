export const data = [
    {
        title: "精品提升课程",
        des: "你的PTE备考学习第一课堂, 从备考小白进阶到8炸大神",
        tabs: ["口语", "写作", "阅读", "听力"], 
        slides: [
            [{cardTitle: "PTE考试介绍", cardDes: ""},
             {cardTitle: "PTE考试介绍", cardDes: ""}, 
             {cardTitle: "PTE考试介绍", cardDes: ""}], 

            [{cardTitle: "PTE口语介绍", cardDes: ""}, 
             {cardTitle: "PTE考试介绍", cardDes: ""}, 
             {cardTitle: "PTE考试介绍", cardDes: ""}, 
             {cardTitle: "PTE考试介绍", cardDes: ""},
             {cardTitle: "PTE考试介绍", cardDes: ""}],

            [{cardTitle: "PTE口语介绍", cardDes: ""}, 
             {cardTitle: "PTE考试介绍", cardDes: ""}, 
             {cardTitle: "PTE考试介绍", cardDes: ""}, 
             {cardTitle: "PTE考试介绍", cardDes: ""}],

            [{cardTitle: "PTE考试介绍", cardDes: ""}, 
             {cardTitle: "PTE考试介绍", cardDes: ""}, 
             {cardTitle: "PTE考试介绍", cardDes: ""}, 
             {cardTitle: "PTE考试介绍", cardDes: ""}]
        ]
    },
    {
        title: "新手入门指南",
        des: "萌新必备, 20个题型概况",
        tabs: ["了解", "口语", "写作", "阅读", "听力"], 
        slides: [
            [{cardTitle: "PTE考试介绍", cardDes: ""}, 
             {cardTitle: "PTE与雅思的区别", cardDes: ""}, 
             {cardTitle: "PTE的备考指南", cardDes: ""}, 
             {cardTitle: "报名流程", cardDes: ""}, 
             {cardTitle: "退改考流程", cardDes: ""}, 
             {cardTitle: "官方机器评分详解", cardDes: ""}, 
             {cardTitle: "10分钟走完模考流程", cardDes: ""}, 
             {cardTitle: "专属复习计划", cardDes: ""}, 
             {cardTitle: "如何分析成绩单", cardDes: ""}, 
             {cardTitle: "PTE认可学校和政府", cardDes: ""}, 
             {cardTitle: "翻译官教你如何做笔记", cardDes: ""}] ,

            [{cardTitle: "PTE口语介绍", cardDes: ""}, 
             {cardTitle: "RA题型介绍", cardDes: ""}, 
             {cardTitle: "DI题型介绍", cardDes: ""}, 
             {cardTitle: "RS题型介绍", cardDes: ""},
             {cardTitle: "RL题型介绍", cardDes: ""},
             {cardTitle: "ASQ题型介绍", cardDes: ""}],

            [{cardTitle: "PTE写作介绍", cardDes: ""}, 
             {cardTitle: "SWT题型介绍", cardDes: ""}, 
             {cardTitle: "WE题型介绍", cardDes: ""}],

            [{cardTitle: "PTE阅读介绍", cardDes: ""}, 
             {cardTitle: "RFIB题型介绍", cardDes: ""}, 
             {cardTitle: "RMCM题型介绍", cardDes: ""}, 
             {cardTitle: "RMCS题型介绍", cardDes: ""}, 
             {cardTitle: "RO题型介绍", cardDes: ""}, 
             {cardTitle: "RWFIB题型介绍", cardDes: ""}],

            [{cardTitle: "PTE听力介绍", cardDes: ""}, 
             {cardTitle: "FIB题型介绍", cardDes: ""}, 
             {cardTitle: "HCS题型介绍", cardDes: ""}, 
             {cardTitle: "HIW题型介绍", cardDes: "大家来找茬"},
             {cardTitle: "MCM题型介绍", cardDes: ""}, 
             {cardTitle: "MCS题型介绍", cardDes: ""},
             {cardTitle: "SMW题型介绍", cardDes: ""}, 
             {cardTitle: "SST题型介绍", cardDes: ""},
             {cardTitle: "WFD题型介绍", cardDes: "尽量不要挑衅机器"}]
        ]
    },
    {
        title: "名师公开课",
        des: "定期解析备考热点，考试动态和技巧",
        tabs: ["口语", "写作", "阅读", "听力"], 
        slides: [
            ["1", "2", "3", "1", "2", "3", "1", "2", "3"], 
            ["2", "3", "3", "1", "2", "3", "1", "2", "3"],
            ["3", "3", "3", "1", "2", "3", "1", "2", "3"],
            ["4", "3", "3", "1", "2", "3", "1", "2", "3"],
            ["5", "3", "3", "1", "2", "3", "1", "2", "3"]
        ]
    },
    {
        title: "蛮牛PTE三分钟",
        des: "",
        tabs: ["口语", "写作", "阅读", "听力"], 
        slides: [
            ["1", "2", "3", "1", "2", "3", "1", "2", "3"], 
            ["2", "3", "3", "1", "2", "3", "1", "2", "3"],
            ["3", "3", "3", "1", "2", "3", "1", "2", "3"],
            ["4", "3", "3", "1", "2", "3", "1", "2", "3"],
            ["5", "3", "3", "1", "2", "3", "1", "2", "3"]
        ]
    },
    {
        title: "蛮牛学员经验分享",
        des: "",
        tabs: ["9炸", "8炸", "7炸"], 
        slides: [
            ["1", "2", "3", "1", "2", "3", "1", "2", "3"], 
            ["2", "3", "3", "1", "2", "3", "1", "2", "3"],
            ["3", "3", "3", "1", "2", "3", "1", "2", "3"]
        ]
    }
]