import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import 'styled-components/macro'
import './popupTested.scss'

export default function PopupTested(props) {
    return (
        <Modal id="mn-modal-feedback" 
        show={props.modalIsShow} onHide={props.hideModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>填写您的考试信息</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
                    <h5> 您的确认考过信息将帮助大家获得更精准的预测 </h5>
                        <div>
                            <form className="el-form el-form--label-top">
                                <div className="el-form-item">
                                    <label className="el-form-item__label">考试日期（必填）</label>
                                    <div className="el-form-item__content">
                                        <div className="el-date-editor el-input el-input--small el-input--prefix el-input--suffix el-date-editor--date">
                                            <input type="text" readonly="readonly" autocomplete="off" name="" placeholder="选择日期" className="el-input__inner" />
                                            <span className="el-input__prefix">
                                                <i className="el-input__icon el-icon-date"></i>
                                            </span>
                                            <span className="el-input__suffix">
                                                <span className="el-input__suffix-inner">
                                                <i className="el-input__icon"></i>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="el-form-item">
                                    <label className="el-form-item__label">考试地点（选填）</label>
                                    <div className="el-form-item__content">
                                        <div className="el-input">
                                            <input type="text" autocomplete="off" className="el-input__inner" />
                                        </div>
                                    </div>
                                </div>
                                <div className="el-form-item">
                                    <label className="el-form-item__label">考试回忆（选填）</label>
                                    <div className="el-form-item__content">
                                        <div className="el-textarea">
                                            <textarea autocomplete="off" rows="3" placeholder="请输入内容" className="el-textarea__inner" css={`min-height: 33px;`}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
    
            <Modal.Footer>
                <Button variant="secondary" onClick={props.hideModal}>取消</Button>
                <Button variant="primary" onClick={props.hideModal}>确认</Button>
            </Modal.Footer>
        </Modal>
    )
}
