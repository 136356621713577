import React from 'react'
import { withTranslation } from 'react-i18next'
import CardUnit from './CardUnit'
import {AiOutlineRight} from 'react-icons/ai'


function StatisticsItem(props) {

    return (
        <div className={"card " + props.className}>
            <a href="/pte/user_center/study_center?type=study">
                <div className="header p-3">
                    {props.data.header.name}
                    <p className="m-0">{props.data.header.btn}</p>
                </div>
                <div className="body p-3">
                    {
                        props.data.body.map((d) => (
                            <CardUnit 
                                title = {d.title}
                                des = {d.des}
                            />
                        ))
                    }
                </div>
                <div className="footer p-3">
                    {props.data.footer}
                    <AiOutlineRight />
                </div>
            </a>
        </div>
    )
}

export default withTranslation()(StatisticsItem)