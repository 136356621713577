import React from 'react'
import auth from '../../utils/auth'
import {Route, Redirect} from 'react-router-dom'
import { PATHS } from '../../global/constants'


export default function ProtecedRoute({component: Component, ...rest}) {
    return (
        <Route {...rest} render={
            (props) => {
                if (auth.authenticated) {
                    console.log("shark,authenticated");

                    return <Component {...props} />
                } else {
                    return <Redirect 
                    to = {{
                        pathname: PATHS.login.name,
                        state: {
                            from: props.location
                        }
                    }}
                    />
                }
            }
        }
        />
       
    )
}
