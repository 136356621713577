import React, {useState} from 'react'
import './fixFooter.scss'
import icon_phone from './images/icon_phone.png'
import icon_close from './images/icon_close.png'
import footer_ad_tea from './images/footer_ad_tea.png'
import fix_footer_bg_m from './images/fix_footer_bg_m.png'

export default function FixFooter(){
    
    const [barIsHide, setBarIsHide] = useState(false);
    const hideBar = () => (
        setBarIsHide(true)
    )


    return (
        <div id="mn-fix-footer" style={{display: barIsHide ? "none" : "display"}}>
            <div className="mn-fix-footer-pc">
                
                <img className="ad-img" src={footer_ad_tea} alt="" />
               
                <div className="ad-text">
                    <p>限时领取PTE大礼包！</p>
                    <p>立马获取价值<span style={{color: "red"}}>AU$200</span>PTE练习材料！</p>
                </div>
                <div className="ad-form">
                    <img className="form-icon" src={icon_phone} alt="" />
                    <input className="form-input" type="text" placeholder="请输入您的手机号" maxLength="11" />
                    <button className="form-submit">免费领取</button>
                </div>

                <img className="ad-close" src={icon_close} alt="" onClick={hideBar} />
            </div>

            <div className="mn-fix-footer-m">
                <img className="ad-bg" src={fix_footer_bg_m} alt="" />
                <img className="ad-close" src={icon_close} alt="" onClick={hideBar}/>
            </div>
        </div>
        
    )
}
