

export const qrData = [
    {
        img: "serviceWechatQR",
        des: "微信客服"
    },
    {
        img: "serviceWhatsAppQR",
        des: "WhatsApp客服"
    },
    {
        img: "appQR",
        des: "iOS APP下载"
    }

]