import React, {useState} from 'react'
import auth from '../../utils/auth'
import { formErrorEnum, loginStateEnum } from './data'
import FieldUsernameEmail from './FieldUsernameEmail'
import FieldMatchPassword from './FieldMatchPassword'
import FieldPassword from './FieldPassword'
import LoginMainBtn from './LoginMainBtn'
import {Form} from 'react-bootstrap'
import 'styled-components/macro'


export default function FormEmailForget(props) {

    // error is seperately for buttons
    const [formState, setFormState] = useState({
        username: formErrorEnum.fieldEmpty, 
        email: "",
        password: formErrorEnum.passwordEmpty, 
        confirmPassword: formErrorEnum.passwordEmpty,
    })


    function toLogin() {

        // reset
        props.setFormInfo({
            username: "", 
            email: "",
            password: "",
            confirmPassword: "",
            phone: "",
            mode: ""
        })
        setFormState({
            username: formErrorEnum.fieldEmpty, 
            email: "",
            password: formErrorEnum.passwordEmpty, 
            confirmPassword: formErrorEnum.passwordEmpty,
        })


        props.setLoginState(loginStateEnum.emailLogin)

    }

    function toRegister() {
        // reset
        props.setFormInfo({
            username: "", 
            email: "",
            password: "",
            confirmPassword: "",
            phone: "",
            mode: ""
        })
        setFormState({
            username: formErrorEnum.fieldEmpty, 
            email: "",
            password: formErrorEnum.passwordEmpty, 
            confirmPassword: formErrorEnum.passwordEmpty,
        })


        props.setLoginState(loginStateEnum.emailRegister)

    }


    return (
        <div className="emailForget" css={`display: ${props.loginState === loginStateEnum.emailForget ? "block" : "none"};`}>

             <Form.Group>
                 <FieldUsernameEmail 
                    formState = {formState}
                    setFormState = {setFormState}
                    formInfo = {props.formInfo}
                    setFormInfo = {props.setFormInfo}
                />

                <FieldPassword
                    formState = {formState}
                    setFormState = {setFormState}
                    formInfo = {props.formInfo}
                    setFormInfo = {props.setFormInfo}
                />
        
                <FieldMatchPassword 
                    formState = {formState}
                    setFormState = {setFormState}
                    formInfo = {props.formInfo}
                    setFormInfo = {props.setFormInfo}
                />
            </Form.Group>

               
            <div className="options pb-3">
                <div 
                    className="register"
                    onClick={()=>{toRegister()}}>
                    立即注册
                 </div>
                 <div 
                    className="forget"
                    onClick={()=>{toLogin()}}>
                    账号登陆
                </div>
             </div>

             <LoginMainBtn 
                formState = {formState}
                onClick = {() => {auth.resetPasswordEmail(props.formInfo)}}
                content = "重置密码"
            />
                        
         </div>
    )
}
