import React from 'react'
import "styled-components/macro";
import {Trans, withTranslation} from 'react-i18next'
import {Link, withRouter} from 'react-router-dom';
import mobileDownload from './images/mn_download_m.png'
import pcLogo from './images/mn_logo.png'
import NavPractice from './NavPractice';
import NavMock from './NavMock'
import './nav.scss'
import NavApp from './NavApp';
import {AiOutlineMenu} from 'react-icons/ai';
import NavLogin from './NavLogin';
import AIScoreTimes from '../../popups/aiScoreTimes/AIScoreTimes';
import { PATHS } from '../../../global/constants';
import { navData } from './data';
import NavMobileUnit from './NavMobileUnit'
const { v4: uuidv4 } = require('uuid');



class Nav extends React.Component {

    constructor(props) {
        super(props);
        
        this.listeners = [];
        this.state = {
          bgOpacity: 0.3, 
          showMobileMenu: false, 
          loginIsShow: false,
          isMobile: window.innerWidth <= 960
        };
        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
        this.showLogin = this.showLogin.bind(this);
        this.hideLogin = this.hideLogin.bind(this);
   
    }


    componentDidMount() {
        // scoll change nav opacity
        this.listeners[0] = document.addEventListener("scroll", e => {
           var scrolled = document.scrollingElement.scrollTop;
           if (scrolled >= 120) {
              if (this.state.status !== 1) {
                 this.setState({ bgOpacity: 1 });
              }
           } else {
              this.setState({bgOpacity: scrolled/120+0.3});
           }
        });
  
  
        // resize window
        this.listeners[1] = window.addEventListener('resize', () => {
           this.setState({
               isMobile: window.innerWidth <= 960
           });
           if (window.innerWidth > 960) {
                this.setState( {
                    showMobileMenu: false
                })
           }
       }, false);
     }


    componentDidUpdate() {
        document.removeEventListener("scroll", this.listeners[0]);
        window.removeEventListener("resize", this.listeners[1]);
     }

    toggleMobileMenu() {
        this.setState(prevState => ({
            showMobileMenu: !prevState.showMobileMenu
        }));
    }

    showLogin() {
        this.setState( {
            loginIsShow:  true
        })
    }
    hideLogin() {
        this.setState( {
            loginIsShow: false
        })
    }



    render() {


        return (
            <header style={{backgroundColor:"rgba(255, 255, 255, "+this.state.bgOpacity+")"}}>

                {/* mobile-download*/}
                <a className="header-download-m" href="https://apps.apple.com/au/app/cow-pte-exam-practice-tool/id1555990766">
                    <img src={mobileDownload} alt="PTE,蛮牛App" />
                </a>

                
                <div className="header-space px-5 clearfix">
                    {/* pc */}
                    <Link to="/" className="logo"><img src={pcLogo} alt="" /></Link>

                    <ul className="header-nav">
                        <li><Link to={PATHS.course.name}><Trans>课程</Trans></Link></li>
                        <li className="practice-hover" css={`position: relative;`}>
                            <Link to={`${PATHS.admin.name}${PATHS.practice.name}`}><Trans>练习库</Trans></Link>
                            <NavPractice key={uuidv4()}/>
                        </li>
                        <li><Link to={`${PATHS.admin.name}${PATHS.memory.name}`}><Trans>机经</Trans></Link></li>
                        <li className="mock-hover" css={`position: relative;`}>
                            <Link to={`${PATHS.admin.name}${PATHS.mock.name}`}><Trans>模考</Trans></Link>
                            <NavMock key={uuidv4()}/>
                        </li>
                        <li><Link to={PATHS.community.name}><Trans>社区</Trans></Link></li>
                        <li><Link to={PATHS.stream.name}><Trans>直播</Trans></Link></li>
                    </ul>
            
                    {/* fr: from right to left */}                    
                    <p className="header-lan fr mx-3">
                        <button onClick={() => (this.props.i18n.changeLanguage("en"))}>En</button>/
                        <button onClick={() => (this.props.i18n.changeLanguage("ch"))}>中文</button>
                    </p>
                    {/* mobile */}
                    <h3 className="header-title-m fl m-0"><Link to='/'><Trans>蛮牛PTE</Trans></Link></h3>
                    <AiOutlineMenu className="header-menu-m fr" onClick={this.toggleMobileMenu}/>
                    <ul className="menu-nav-m" style={{display: this.state.showMobileMenu ? "block" : "none"}}>
                        {
                            navData.map((n, idx) => (
                                <NavMobileUnit 
                                key = {idx}
                                location = {this.props.location}
                                path = {n.path}
                                title = {n.title}
                                />
                            ))
                        }
                    </ul>


                    {/* pc/mobile */}
                    <button className="login-handle fr" onClick={this.showLogin}><Trans>登录/注册</Trans></button>
                    <AIScoreTimes modalIsShow={this.state.loginIsShow} hideModal={this.hideLogin} />
                    {/* pc */}
                    <button className="lxapp fr">
                        <Trans>练习APP</Trans>
                        <NavApp />
                    </button>
                </div>
            </header>
         )
     }
}



export default withRouter(withTranslation()(Nav));