import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './localizations/i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App'

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//    </React.StrictMode>
//    ,
//   document.getElementById('root')
// );


import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}




// ReactDOMServer.renderToStaticMarkup(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>, 
//   document.getElementById('root')
// )
