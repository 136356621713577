import React from 'react'
import FixFooter from '../general/fixFooter/FixFooter'
import FixSidebar from '../general/fixSidebar/FixSidebar'
import Footer from '../general/footer/Footer'
import Nav from '../general/nav/Nav'

export default function CommunityPage() {
    return (
        <div>
            <Nav />
            <main id="mn-communityPage">
            Community Page
            </main>
            <Footer />
            <FixSidebar />
            <FixFooter />
        </div>
    )
}
